import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import { useModuleAccess } from '../../../../helper';
import { constants } from '../../../../constants';

function HiringThisJobIs(props) {
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Read);
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>

                </AdminLayout>
            )}
        </>
    )
}

export default HiringThisJobIs;