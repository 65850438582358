import React, { useEffect } from 'react';
import AuthLayout from '../../components/layouts/authLayout';
import { Formik } from 'formik';
import * as Yup from "yup";
import { constants } from '../../constants';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../components/helper/Input';
import { useForgotPasswordMutation } from '../../rtk/services/authApi';
import { toast } from 'react-toastify';
import Loader from '../../components/loader';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email(constants?.validations?.invalidEmail)
        .required(constants?.validations?.requiredEmail),
});

const initialValues = {
    email: "",
}

function ForgotPassword(props) {
    const navigate = useNavigate();
    const [forgotUser, res] = useForgotPasswordMutation();
    const handleSubmit = async (values) => {
        await forgotUser({
            redirect_uri: window.location.origin + "/reset-password",
            email_phone: values?.email
        })
    }
    useEffect(() => {
        if (res.isSuccess) {
            toast.success(res?.data?.message);
            navigate('/');
        }
    }, [res.isSuccess, res?.data, navigate]);
    return (
        <AuthLayout>
            {res.isLoading && (<Loader />)}
            <div className="form_info">
                <h5 className="title mb-2 text-white">Forgot Password?</h5>
                <p className="subtext">Provide your email to reset your password</p>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit} className="formbox mt-4">
                                <Input type="email" name="email" placeholder="Email ID" />
                                <div className="mb-3 forgotPass">
                                    {/* Forgot password link */}
                                    <Link to={"/login"} className="link">Login</Link>
                                </div>
                                {/* Sign In button */}
                                <button type="submit" className="btn btn-primary w-100">
                                    Sign In
                                </button>
                            </form>
                        );
                    }}
                </Formik>
            </div>

        </AuthLayout>
    );
}

export default ForgotPassword;