import React from 'react';

function Loader(props) {
    return (
        <div className="spinny-loader">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default Loader;