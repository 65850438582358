import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const lookupsApi = createApi({
    reducerPath: "lookupsApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getLookupsList: builder.mutation({
            query: (body) => {
                const url = `search_keyword=${body.search_keyword}&page_limit=${body.page_limit}&page_offset=${body.page_offset}`;
                return {
                    url: `/admin/lookups/industry?${url}`,
                    method: "get"
                }
            },
        }),
        addLookups: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/industry/`,
                    method: "post",
                    body
                }
            },
        }),
        editLookups: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/industry/`,
                    method: "post",
                    body
                }
            },
        }),
        deleteLookups: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/industry?industry_uuid=${body?.industry_uuid}`,
                    method: "delete",
                    body
                }
            },
        }),
        getDepartmentList: builder.mutation({
            query: (body) => {
                const url = `search_keyword=${body?.search_keyword}&page_limit=${body?.page_limit}&page_offset=${body?.page_offset}&industry_uuid=${body?.industry_uuid}`;
                return {
                    url: `/admin/lookups/department?${url}`,
                    method: "get"
                }
            },
        }),
        addEditDepartment: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/department/`,
                    method: "post",
                    body
                }
            },
        }),
        deleteDepartment: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/department?department_id=${body?.department_id}`,
                    method: "delete",
                    body
                }
            },
        }),

        getRoleList: builder.mutation({
            query: (body) => {
                const url = `search_keyword=${body?.search_keyword}&page_limit=${body?.page_limit}&page_offset=${body?.page_offset}&industry_uuid=${body?.industry_uuid}`;
                return {
                    url: `/admin/lookups/role?${url}`,
                    method: "get"
                }
            },
        }),
        addEditRole: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/role/`,
                    method: "post",
                    body
                }
            },
        }),
        deleteRole: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/role?job_role_id=${body?.job_role_id}`,
                    method: "delete",
                    body
                }
            },
        }),
        getHiringQuestionSingle: builder.mutation({
            query: (body) => {
                let healthCare = "";
                if (body?.is_healthcare == 1 || body?.is_healthcare == 0) {
                    healthCare = `is_healthcare=${body?.is_healthcare}&`
                }
                const url = `${healthCare}search_keyword=${body?.search_keyword || ""}&page_limit=${body?.page_limit || 10}&page_offset=${body?.page_offset || 1}&hiring_type=${body?.hiring_type || ""}`;
                return {
                    url: `/admin/lookups/hiring-questions/?${url}`,
                    method: "get"
                }
            },
        }),
        getPostInterviewQuestionList: builder.mutation({
            query: (body) => {
                const url = `&page_limit=${body?.page_limit}&page_offset=${body?.page_offset}&user_type=${body?.user_type || ""}`;
                return {
                    url: `/admin/lookups/questions?${url}`,
                    method: "get"
                }
            },
        }),
        deletePostInterviewQuestion: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/questions`,
                    method: "post",
                    body
                }
            },
        }),
        addPostInterviewQuestion: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/questions`,
                    method: "post",
                    body
                }
            },
        }),
        updatePostInterviewQuestion: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/questions`,
                    method: "post",
                    body
                }
            },
        }),
        updateHiringGoal: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/hiring-goal/`,
                    method: "post",
                    body
                }
            },
        }),
        updateHiringQuestions: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/hiring-questions/`,
                    method: "put",
                    body
                }
            },
        }),
        addUpdateSeniorityLevel: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/seniority/`,
                    method: "post",
                    body
                }
            },
        }),
        updateSeniorityOrder: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/seniority/`,
                    method: "put",
                    body
                }
            },
        }),
        addUpdateLanguage: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/software-langauge/`,
                    method: "post",
                    body
                }
            },
        }),
        addUpdateBenefits: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/benefits/`,
                    method: "post",
                    body
                }
            },
        }),
        getDepartmentListSingle: builder.mutation({
            query: (body) => {
                const url = `search_keyword=${body?.search_keyword || ""}&page_limit=${body?.page_limit || 10}&page_offset=${body?.page_offset || 1}`;
                return {
                    url: `/admin/lookups/department-list?${url}`,
                    method: "get"
                }
            },
        }),
        addDepartmentSingle: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/department-list`,
                    method: "post",
                    body
                }
            },
        }),
        editDepartmentSingle: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/department-list`,
                    method: "post",
                    body
                }
            },
        }),
        getTotalCompensation: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/compensation`,
                    method: "get",
                }
            },
        }),
        updateTotalCompensation: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/compensation`,
                    method: "put",
                    body
                }
            },
        }),
        updateResponsibility: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/responsibility`,
                    method: "put",
                    body
                }
            },
        }),
        updateRoleExperience: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/role-experience`,
                    method: "put",
                    body
                }
            },
        }),
        updateWorkExperience: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/work-experience`,
                    method: "put",
                    body
                }
            },
        }),
        updateEducationLevel: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/lookups/educational-level`,
                    method: "put",
                    body
                }
            },
        }),
    }),
});

export const {
    useGetLookupsListMutation,
    useAddLookupsMutation,
    useEditLookupsMutation,
    useDeleteLookupsMutation,

    useGetDepartmentListMutation,
    useAddEditDepartmentMutation,
    useDeleteDepartmentMutation,

    useGetRoleListMutation,
    useAddEditRoleMutation,
    useDeleteRoleMutation,
    useGetHiringQuestionSingleMutation,
    useGetPostInterviewQuestionListMutation,
    useDeletePostInterviewQuestionMutation,
    useAddPostInterviewQuestionMutation,
    useUpdatePostInterviewQuestionMutation,

    useUpdateHiringGoalMutation,
    useUpdateHiringQuestionsMutation,
    useAddUpdateSeniorityLevelMutation,
    useUpdateSeniorityOrderMutation,
    useAddUpdateLanguageMutation,
    useAddUpdateBenefitsMutation,

    useGetDepartmentListSingleMutation,
    useAddDepartmentSingleMutation,
    useEditDepartmentSingleMutation,
    useGetTotalCompensationMutation,
    useUpdateTotalCompensationMutation,
    useUpdateResponsibilityMutation,
    useUpdateRoleExperienceMutation,
    useUpdateWorkExperienceMutation,
    useUpdateEducationLevelMutation,
} = lookupsApi;
