import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import { useLocation, useParams } from 'react-router-dom';
import { useUserFeedbackListMutation } from '../../../../rtk/services/userApi';
import Loader from '../../../../components/loader';
import { Alert } from 'react-bootstrap';
import { decryptKeys } from '../../../../helper';

function HmJobFeedbackDetail(props) {
    const location = useLocation();
    const { user_uuid, job_uuid, feedback_type, historyId } = useParams();
    const feedback = feedback_type === "PRE-INTERVIEW" ? 1 : feedback_type === "POST-INTERVIEW" ? 2 : "";
    const [user, setUser] = useState(null);
    const [item, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserFeedbackListMutation();
    useEffect(() => {
        getList({
            user_uuid: user_uuid,
            job_uuid: job_uuid,
            feedback_stage: feedback,
            history_id: historyId
        });
    }, [getList && (user_uuid && job_uuid && feedback && historyId)]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data);
        }
    }, [resData, resSuccess]);
    useEffect(() => {
        if (location?.search?.includes("keys")) {
            let params = new URLSearchParams(location.search);
            const data = params.get("keys");
            let items = decryptKeys(data?.replaceAll(" ", "+"));
            setUser(items);
        }
    }, [location.search])
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Candidate info</h5>
                </div>
                <div className="card-body">
                    <Alert variant="dark" className='payment-card mb-3'>
                        <Alert.Heading className='d-flex justify-content-between mb-3 h6'><p className='mb-0'>candidate &nbsp;{user?.user_unique_name || "-"} - {user?.candidate_first_name || "-"} {user?.candidate_last_name || ""}</p></Alert.Heading>
                        <div>
                            <div className='row mb-3'>
                                <div className="col-lg-2">job</div>
                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{user?.job_id || "-"} - {user?.job_title || "-"}</span></div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-2">feedback stage</div>
                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{user?.feedback_type || "-"}</span></div>
                            </div>
                        </div>
                    </Alert>
                    {/*  */}
                    {feedback === 1 && (
                        <div className="card mb-0">
                            <div className="card-header">
                                <h5 className="card-title">Reason for job rejection</h5>
                            </div>
                            <div className="card-body">
                                <ul className='row'>
                                    {item?.rejection?.map((elm, i) => (
                                        <li key={i} className='col-auto'>
                                            <div className="custom_checkbox">
                                                <label htmlFor={elm?.title?.replaceAll(" ", "")}>
                                                    <input type="checkbox" id={elm?.title?.replaceAll(" ", "")} className="form-check-input me-2" defaultChecked={true} />
                                                    <span>{elm?.title || "-"}</span>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    {/*  */}
                    {/*  */}
                    {feedback === 2 && (
                        <div className="card mb-0">
                            <div className="card-header">
                                <h5 className="card-title">Feedback by hiring manager</h5>
                            </div>
                            <div className="card-body">
                                {/*  */}
                                {item?.map((elm, i) => (
                                    <div className={`row ${i !== item.length - 1 ? 'mb-4' : ''}`} key={i}>
                                        <div className="col-auto">
                                            <div className="custom_checkbox">
                                                <label htmlFor="titleOne" className='pe-0'>
                                                    <input type="checkbox" id="titleOne" className="form-check-input me-2" defaultChecked={true} />
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <h6 className='text-white mb-2'>{elm?.question || "-"}</h6>
                                            <p className='text-white mb-0'>{elm?.response_option || "-"}</p>
                                            {elm?.sub_options?.length > 0 && (
                                                <div className="row mt-3">
                                                    {elm?.sub_options?.map((sub, j) => (
                                                        <div key={j} className="col-auto">
                                                            <div className="custom_checkbox">
                                                                <label className=' px-3 mb-0'>
                                                                    <input type="checkbox" className="form-check-input me-2" defaultChecked={true} />
                                                                    <span>{sub?.sub_option_text || "-"}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {/*  */}
                            </div>
                        </div>
                    )}
                    {/*  */}
                </div>
            </div>
        </AdminLayout>
    );
}

export default HmJobFeedbackDetail;