import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { constants } from '../../constants';
import { setJobsFalse } from '../../rtk/feature/commonSlice';

function Navbar(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const [userAccess, setUserAccess] = useState(null);
    const { access } = useSelector(state => state.auth);
    const { jobs } = useSelector(state => state.common);
    const [lookupsDropdown, setLookupsDropdown] = useState(false);
    const [softSkillsDropdown, setSoftSkillsDropdown] = useState(false);
    const [adminDropdown, setAdminDropdown] = useState(false);
    useEffect(() => {
        if (access?.length > 0) {
            setUserAccess(JSON.parse(access))
        }
    }, [access])
    const handleNavigation = () => {
        dispatch(setJobsFalse())
    }
    const canRead = (moduleName) => {
        const hasReadPrivilege = userAccess?.find((elm, i) => elm?.module_name == moduleName)?.privileges
            ?.some((perm, j) => perm?.privilege_name == constants?.privilegeType.Read)
        return hasReadPrivilege;
    }

    useEffect(() => {
        if (location?.pathname?.includes('lookups')) {
            setLookupsDropdown(true);
        } else if (location?.pathname?.includes('soft-skills-quiz')) {
            setSoftSkillsDropdown(true);
        } else if (location?.pathname?.includes('admin')) {
            setAdminDropdown(true);
        }
    }, [location])
    return (
        <div className="navbar p-0">
            <div className="desk_logo">
                <Link to="/dashboard" className="text-center">
                    <img src="/assets/images/logo-3.png" className="image-fit-contain" alt="logo" />
                </Link>
            </div>
            <nav className="navigation w-100">
                <ul className="main_nav">
                    {canRead(constants.moduleType.Dashboard) && (
                        <li className="menu-item">
                            <Link to="/dashboard" onClick={() => handleNavigation()} className={location?.pathname?.includes('dashboard') ? "active" : ""}>Dashboard</Link>
                        </li>
                    )}
                    {canRead(constants.moduleType.User_management) && (
                        <li className="menu-item">
                            <Link to="/users" onClick={() => handleNavigation()} className={location?.pathname?.includes('user') && !jobs ? "active" : ""} >User management</Link>
                        </li>
                    )}
                    {canRead(constants.moduleType.Jobs_management) && (
                        <li className="menu-item">
                            <Link to="/jobs" onClick={() => handleNavigation()} className={location?.pathname?.includes('jobs') || jobs ? "active" : ""} >Jobs management</Link>
                        </li>
                    )}
                    {canRead(constants.moduleType.Manage_Skill_Quiz) && (
                        <li onClick={() => handleNavigation()} className={`menu-item has-submenu ${location?.pathname?.includes('soft-skills-quiz') ? "active" : ""}`} >
                            <Link to="#" className={(location?.pathname?.includes('soft-skills-quiz') || softSkillsDropdown) ? "active" : ""} onClick={() => setSoftSkillsDropdown(!softSkillsDropdown)}>Manage Skill Quiz</Link>
                            <ul className={`sub-menu ${softSkillsDropdown ? "show" : ""}`}>
                                <li className='menu-item'>
                                    <Link to="/soft-skills-quiz/list" className={location?.pathname?.includes('soft-skills-quiz/list') ? "active" : ""}>soft skills</Link>
                                </li>
                                <li className='menu-item'>
                                    <Link to="/soft-skills-quiz/score" className={location?.pathname?.includes('/soft-skills-quiz/score') ? "active" : ""}>scoring key</Link>
                                </li>
                            </ul>
                        </li>
                    )}
                    {canRead(constants.moduleType.Manage_Lookups) && (
                        <li onClick={() => handleNavigation()} className={`menu-item has-submenu ${location?.pathname?.includes('lookups') ? "active" : ""}`} >
                            <Link to="#" className={(location?.pathname?.includes('lookups') || lookupsDropdown) ? "active" : ""} onClick={() => setLookupsDropdown(!lookupsDropdown)}>Manage Lookups</Link>
                            <ul className={`sub-menu ${lookupsDropdown ? "show" : ""}`}>
                                <li className="menu-item">
                                    <Link to="/lookups/hiring" className={location?.pathname?.includes('/lookups/hiring') ? "active" : ""}>Hiring Questions</Link>
                                </li>
                                <li className='menu-item'>
                                    <Link to="/lookups/industry" className={(location?.pathname?.includes('lookups/industry') || location?.pathname?.includes('lookups/department') || location?.pathname?.includes('lookups/role')) ? "active" : ""}>Industry Listing</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/lookups/interview-questions" className={location?.pathname?.includes('lookups/interview-questions') ? "active" : ""}> Post Interview Questions </Link>
                                </li>
                                {/* <li className="menu-item">
                                <Link to="/lookups/match-criteria" className={location?.pathname?.includes('lookups/match-criteria') ? "active" : ""}>Match Criteria</Link>
                            </li> */}
                            </ul>
                        </li>
                    )}
                    {canRead(constants.moduleType.Reported_Users) && (
                        <li onClick={() => handleNavigation()} className="menu-item">
                            <Link to="/reported" className={location?.pathname?.includes('reported') ? "active" : ""}>Reported Users</Link>
                        </li>
                    )}
                    {canRead(constants.moduleType.Invoice_Management) && (
                        <li onClick={() => handleNavigation()} className="menu-item">
                            <Link to="/invoice" className={location?.pathname?.includes('invoice') ? "active" : ""}>Invoice Management</Link>
                        </li>
                    )}
                    {canRead(constants.moduleType.Administrative_Panel) && (
                        <li onClick={() => handleNavigation()} className={`menu-item has-submenu ${location?.pathname?.includes('admin') ? "active" : ""}`} >
                            <Link to="#" className={(location?.pathname?.includes('admin') || adminDropdown) ? "active" : ""} onClick={() => setAdminDropdown(!adminDropdown)}>Administrative Panel</Link>
                            <ul className={`sub-menu ${adminDropdown ? "show" : ""}`}>
                                {canRead(constants.moduleType.Roles) && (
                                    <li className="menu-item">
                                        <Link to="/admin/roles" className={location?.pathname?.includes('admin/role') ? "active" : ""}>roles</Link>
                                    </li>
                                )}
                                <li className="menu-item">
                                    <Link to="/admin/list" className={location?.pathname?.includes('admin/list') ? "active" : ""}>admin users</Link>
                                </li>
                            </ul>
                        </li>
                    )}
                    {canRead(constants.moduleType.Pricing) && (
                        <li onClick={() => handleNavigation()} className="menu-item">
                            <Link to="/pricing/company-info" className={location?.pathname?.includes('pricing') ? "active" : ""}>Pricing</Link>
                        </li>
                    )}
                    {canRead(constants.moduleType.Soft_skills_answer) && (
                        <li onClick={() => handleNavigation()} className="menu-item">
                            <Link to="/soft-skills-listing" className={location?.pathname?.includes('soft-skills-listing') ? "active" : ""}>soft skills answer</Link>
                        </li>
                    )}
                    {canRead(constants.moduleType.Export_requests) && (
                        <li onClick={() => handleNavigation()} className="menu-item">
                            <Link to="/export" className={location?.pathname?.includes('export') ? "active" : ""}>export requests</Link>
                        </li>
                    )}
                </ul>
            </nav >
        </div >
    );
}

export default Navbar;