import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import JsJobInfo from '../components/js-job-info';
import JsJobSearchTabs from '../components/js-job-search-tabs';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCandidateRefineDetailMutation } from '../../../../rtk/services/userApi';
import Loader from '../../../../components/loader';
import { intToBool, priceFormat, roundFigureData } from '../../../../helper';
import { Col, Row } from 'react-bootstrap';
import { constants } from '../../../../constants';

function JsJobSearchRefineDetail(props) {
    const dispatch = useDispatch();
    const { search_id } = useParams();
    const [itemRefine, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useCandidateRefineDetailMutation();
    useEffect(() => {
        getList({ job_history_id: search_id });
    }, [getList, search_id]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data);
        }
    }, [dispatch, resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <JsJobInfo>
                <JsJobSearchTabs />
                {/*  */}
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="card-title">refine search</h5>
                    </div>
                    <div className="card-body details_style">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>job search title</label>
                                    <p className="mb-0">{itemRefine?.job_search_title || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>company type</label>
                                    <p className="mb-0">{itemRefine?.company_info?.company_type || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>company size</label>
                                    <p className="mb-0">{itemRefine?.company_info?.company_size || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>industry</label>
                                    <p className="mb-0">{itemRefine?.industry_info?.industry || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>department</label>
                                    <p className="mb-0">{itemRefine?.department_info?.department_title || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>seniority level</label>
                                    <p className="mb-0 comma-list" >{itemRefine?.seniority?.map((elm, i) => (<span key={i}>{elm?.level}</span>))}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>responsibility</label>
                                    <p className="mb-0">{itemRefine?.responsiblity_info?.title || "-"}  {itemRefine?.responsiblity_info?.sub_responsibility_title && (<span>{'> ' + itemRefine?.responsiblity_info?.sub_responsibility_title}</span>)}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>working style</label>
                                    <p className="mb-0">{itemRefine?.job_details?.working_cycle || "-"}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-group">
                                    <label>location</label>
                                    <>
                                        {itemRefine?.location_info?.length > 0 ? itemRefine?.location_info?.map((elm, i) => (
                                            <Row key={i}>
                                                {intToBool(elm.is_country) && (<Col sm={"auto"} key={i}>{elm?.country}</Col>)}
                                                {intToBool(elm.is_state) && (<Col sm={"auto"} key={i}>{elm?.state}, {elm?.country}</Col>)}
                                                {intToBool(elm.is_city) && (
                                                    <>
                                                        <Col sm={"auto"} key={i}>{elm?.city}, {elm?.state}, {elm?.country}</Col>
                                                        <Col sm={"auto"}><strong>radius</strong> <span className='ms-3' /> {elm?.radius}</Col>
                                                    </>
                                                )}
                                            </Row>
                                        )) : "-"}
                                    </>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="text-group">
                                    <label>total compensation</label>
                                    <>
                                        <p className='mb-2'>{priceFormat(itemRefine?.job_details?.total_compensation) || "-"}/{itemRefine?.job_details?.salary_pay_periods}</p>
                                        <div className="row">
                                            <div className="col-auto"><strong>i'd like to work</strong> <span className='ms-3' />{itemRefine?.job_details?.job_type || "-"}</div>
                                            <div className="col-auto"><strong>salary</strong> <span className='ms-3' /> {roundFigureData(itemRefine?.job_details?.salary_from_range, itemRefine?.job_details?.salary_to_range)}/{itemRefine?.job_details?.salary_pay_periods}</div>
                                            <div className="col-auto"><strong>commission</strong> <span className='ms-3' /> {roundFigureData(itemRefine?.job_details?.commission_from_range, itemRefine?.job_details?.commission_to_range)}/{itemRefine?.job_details?.salary_pay_periods}</div>
                                            <div className="col-auto"><strong>bonus</strong> <span className='ms-3' /> {roundFigureData(itemRefine?.job_details?.bonus_from_range, itemRefine?.job_details?.bonusToRange)}/{itemRefine?.job_details?.salary_pay_periods}</div>
                                        </div>
                                    </>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="text-group no-detail-style mb-0">
                                    <label>benefits</label>
                                    <p className="mb-0 comma-list">{itemRefine?.benefits_obj_list?.benefits_obj_list?.map((elm, i) => (
                                        <>
                                            {elm?.benefit_id !== constants.otherBenefitId ? <span key={i}>{elm?.title || "-"}</span> : <span key={i}>{itemRefine?.benefits_obj_list?.custom_benefit || "-"}</span>}
                                        </>
                                    ))}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
            </JsJobInfo>
        </AdminLayout>
    );
}

export default JsJobSearchRefineDetail;