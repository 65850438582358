import React, { forwardRef, useCallback, useEffect } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { constants } from '../../../constants';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import ReactDatePicker from 'react-datepicker';
import CommonPagination from '../../../components/shared/CommonPagination';
import { Link, useLocation } from 'react-router-dom';
import { useGetSoftSkillsListMutation } from '../../../rtk/services/softSkillsApi';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import moment from 'moment';
import Loader from '../../../components/loader';
import { getDecodedCountryCode, getPhoneNumber, handleCopyItem, useModuleAccess } from '../../../helper';
import ExportButton from '../../../components/helper/exportButton';

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="form-group mb-0" >
        <input type="text" ref={ref} value={value} onClick={onClick} className='form-control' placeholder={constants.common.dateFormat} />
    </div>
));

function SoftSkillsListing(props) {
    const location = useLocation();
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetSoftSkillsListMutation();
    const [itemList, setItemList] = useState([]);
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Soft_skills_answer, constants.privilegeType.Read);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [searchKey, setSearchKey] = useState("");
    const [searchValue] = useDebounce(searchKey, 1000);
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [userType, setUserType] = useState("");
    const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    // const [bothDates, setBothDates] = useState(null);
    const getItemList = useCallback(() => {
        getList({
            user_type: userType == 2 ? "" : userType,
            is_invited_peer: userType == 2 ? 1 : "",
            search_keyword: searchValue,
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            skill_test_date: startDate && moment(startDate).format("YYYY-MM-DD")
        });
    }, [userType, pageLimit, pageOffset, getList, searchValue, startDate]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.data?.[0]?.total_count);
        }
    }, [resData, resSuccess]);
    // const dates = startDate && endDate;
    const onChange = (dates) => {
        setStartDate(dates);
        // const [start, end] = dates;
        // setStartDate(start);
        // setEndDate(end);
        // if (start && end) {
        //     setPageOffset(0);
        // }
    };
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (location.search?.includes("pageOffset")) {
            let params = new URLSearchParams(location.search);
            const search = params.get("search");
            const userType = params.get("userType");
            const startDate = params.get("startDate");
            const pageOffset = params.get("pageOffset");
            setSearchKey(search);
            setUserType(userType);
            if (startDate !== "null") {
                setStartDate(startDate)
            }
            setPageOffset(parseInt(pageOffset));
        }
    }, [location.search])
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resLoading && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">soft skill test users ({totalCount || 0})</h5>
                            <div className="action_btns">
                                <ExportButton type={constants.exportKeys.soft_skill_users} />
                            </div>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-group">
                                        <input type="text" className='form-control' value={searchKey} placeholder='Search by name, email, phone number' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="form-group select-option">
                                        <select className="form-control form-select" value={userType} onChange={(e) => { setUserType(e.target.value); setPageOffset(0) }}>
                                            {constants.userTypeInvites?.map((item, i) => (
                                                <option key={i} value={item?.value}>{item?.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className='position-relative date-picker'>
                                        <ReactDatePicker
                                            dateFormat={constants.common.dateFormat}
                                            placeholderText={constants.common.dateFormat}
                                            selected={startDate}
                                            onChange={onChange}
                                            startDate={startDate}
                                            // endDate={endDate}
                                            isClearable={true}
                                            showClearButton={true}
                                            // selectsRange
                                            maxDate={new Date()}
                                            clearButtonClassName="btn btn-primary btn-sm btn-icon"
                                            customInput={<ExampleCustomInput />}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>id</th>
                                            <th>name</th>
                                            <th>user type</th>
                                            <th>email</th>
                                            <th>number</th>
                                            <th>date</th>
                                            <th>job id</th>
                                            <th>job history id</th>
                                            <th>seniority level</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ? itemList?.map((item, i) => (
                                                <tr key={i}>
                                                    <td data-label="id">{item?.user_id || "-"}</td>
                                                    <td data-label="name">{item?.first_name || "-"} {item?.last_name || "-"}</td>
                                                    <td data-label="user type">{constants.USER_TYPE[item?.user_type_Id] || "-"}</td>
                                                    <td data-label="email">{item?.work_email || item?.personal_email || "-"}</td>
                                                    <td data-label="number">{getDecodedCountryCode(item?.country_code) + " " + (getPhoneNumber(item?.user_type_Id, item)) || "-"}
                                                        <i className="bi bi-clipboard-fill isCopy ms-2 cursor-pointer" onClick={() => {
                                                            if (item?.user_type_Id === constants.userTypeId.hm) {
                                                                handleCopyItem(item?.mobile_number);
                                                            } else if (item?.user_type_Id === constants.userTypeId.js) {
                                                                handleCopyItem(item?.personal_number);
                                                            } else {
                                                                handleCopyItem(item?.mobile_number);
                                                            }
                                                        }} />
                                                    </td>
                                                    <td data-label="date">{moment.utc(item?.skill_test_date).local().format("MM/DD/YYYY hh:ss a") || "-"}</td>
                                                    <td data-label="job id">{item?.job_id || "-"}</td>
                                                    <td data-label="job history id">{item?.js_history_id || "-"}</td>
                                                    <td data-label="seniority level">{item?.seniority_level || "-"}</td>
                                                    {hasReadPrivilege && (
                                                        <td data-label="action">
                                                            <div className="action_wrap">
                                                                <Link to={`/soft-skills-listing/list/${item?.user_id}/${item?.job_id}/${item?.js_history_id}/${item?.user_type_Id}?search=${searchKey}&userType=${userType}&startDate=${startDate}&pageOffset=${pageOffset}&type=softSkillDetail`} className="btn btn-icon">
                                                                    <i className="bi-eye" />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                                :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                        </div>
                    </div>
                </AdminLayout >
            )}
        </>
    );
}

export default SoftSkillsListing;