import React, { Fragment, useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import UserTabs from '../components/userTabs';
import { Link, useParams } from 'react-router-dom';
import { constants } from '../../../../constants';
import { useUserJobPostedMutation } from '../../../../rtk/services/userApi';
import CommonPagination from '../../../../components/shared/CommonPagination';
import Loader from '../../../../components/loader';
import { dateFormat, intToBool } from '../../../../helper';
import moment from 'moment';

function HmJobPosts(props) {
    const { userId } = useParams();
    const pageLimit = constants.common.limit - 1;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [totalCount, setTotalCount] = useState(0);
    const [status, setStatus] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [itemList, setItemList] = useState([]);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserJobPostedMutation();
    useEffect(() => {
        getList({
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            user_uuid: userId,
            search_type: status,
            payment_status: paymentStatus
        });
    }, [getList, userId, status, paymentStatus, pageLimit, pageOffset]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title col-lg-4">User Job Posts</h5>
                    <div className="action_btns mt-3 mb-2 col-lg-8">
                        <div className="row justify-content-end">
                            <div className="col-md-4">
                                <div className="form-group mb-0 select-option">
                                    <select className="form-select" onChange={(e) => { setStatus(e.target.value); setPageOffset(0); }}>
                                        {constants.statusJobType.map((item, i) => (
                                            <option value={item.value} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group mb-0 select-option">
                                    <select className="form-select" onChange={(e) => { setPaymentStatus(e.target.value); setPageOffset(0); }}>
                                        {constants.statusPaymentType.map((item, i) => (
                                            <option value={item.value} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <UserTabs />
                    <div className="row">
                        {itemList?.length > 0 ? itemList?.map((job, i) => (
                            <div className="col-lg-4" key={i}>
                                {/* job card */}
                                <div className="card job-card">
                                    <div className="card-header">
                                        <h6 className="card-title fs-16">Job id : {job?.job_id}</h6>
                                        <div className="action_btns mb-0">
                                            {intToBool(job?.is_active) && moment.utc(job?.job_trial_expired_date).local().diff(moment(), "seconds") > 0 && (<span className='badge rounded-pill'>active</span>)}
                                            {(intToBool(job?.is_active) && moment.utc(job?.job_trial_expired_date).local().diff(moment(), "seconds") < 0) || intToBool(job?.is_expired) && (<span className='badge bg-danger rounded-pill'>expired</span>)}

                                            {intToBool(job?.job_payment_status) && (<span className='badge bg-info rounded-pill ms-3'>paid full</span>)}
                                            {(intToBool(job?.feedback_payment_status) && !intToBool(job?.job_payment_status)) && (<span className='badge bg-info rounded-pill ms-3'>paid feedback</span>)}
                                            {(!intToBool(job?.feedback_payment_status) && !intToBool(job?.job_payment_status) && moment.utc(job.job_trial_expired_date).local().isSameOrAfter()) && (<span className='badge bg-danger rounded-pill ms-3'>unpaid</span>)}
                                            {(!intToBool(job?.feedback_payment_status) && !intToBool(job?.job_payment_status) && !moment.utc(job.job_trial_expired_date).local().isSameOrAfter()) && (<span className='badge bg-danger rounded-pill ms-3'>expired</span>)}
                                        </div>
                                    </div>
                                    <div className="card-body text-center">
                                        <h5 className='title mb-4'>{job?.custom_role ? job?.custom_role : job?.role_title || "-"}</h5>
                                        <p className='text-white mb-3'>{constants.JOB_TYPE[job?.job_type] || "-"} | {job?.seniority || "-"} | {job?.responsibility || "-"}</p>
                                        <p className='text-white mb-3'>
                                            <i className="fa-solid fa-location-dot me-2" />
                                            {job?.job_location?.slice(0, 1)?.map((elm, j) => (
                                                <Fragment key={j}>
                                                    {intToBool(elm.is_country) && (<span key={j + 'country'}>{elm?.country}</span>)}
                                                    {intToBool(elm.is_state) && (<span key={j + 'state'}>{elm?.state}, {elm?.country}</span>)}
                                                    {intToBool(elm.is_city) && (<span key={j + 'city'}>{elm?.city}, {elm?.state}, {elm?.country}</span>)}
                                                </Fragment>
                                            )) || '-'}
                                            {job?.job_location?.length > 1 && (<strong className='ms-2'>& {job?.job_location?.length - 1} more</strong>)}
                                        </p>
                                        <p className='text-white mb-2'>Posted On : {dateFormat(job?.job_posted_at, 'DD MMM YYYY')}</p>
                                    </div>
                                    <div className="card-footer">
                                        <Link to={`/user/job/details/${job?.job_uuid}`} className='btn btn-primary btn-sm fs-14 px-3 py-2 w-100'>view details</Link>
                                    </div>
                                    <div className="inner" />
                                </div>
                                {/* job card */}
                            </div>
                        )) : <div className='col-12'><div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div></div>}
                    </div>
                </div>
                <div className="card-footer">
                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                </div>
            </div>
        </AdminLayout >
    );
}

export default HmJobPosts;