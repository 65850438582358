import React from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useState } from 'react';
import { constants } from '../../../constants';
import CommonPagination from '../../../components/shared/CommonPagination';
import { useDebounce } from 'use-debounce';
import { useUserPaymentHistoryMutation } from '../../../rtk/services/userApi';
import { useCallback } from 'react';
import { useEffect } from 'react';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { getDecodedCountryCode, intToBool, phoneNumberAutoFormat, priceFormat, useModuleAccess } from '../../../helper';
import moment from 'moment';
import ExportButton from '../../../components/helper/exportButton';

function InvoiceList(props) {
    const [itemList, setItemList] = useState([]);
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Invoice_Management, constants.privilegeType.Read);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [searchKey, setSearchKey] = useState("");
    const [searchValue] = useDebounce(searchKey, 1000);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserPaymentHistoryMutation();
    const getItemList = useCallback(() => {
        getList({
            search_keyword: searchValue,
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
        });
    }, [pageLimit, pageOffset, getList, searchValue]);
    useEffect(() => {
        getItemList();
    }, [getItemList])
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resSuccess]);
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resFetching && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">invoice management ({totalCount})</h5>
                            <div className="action_btns">
                                <ExportButton type={constants.exportKeys.invoices} />
                            </div>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className='form-control' placeholder='Search by user name, email, phone no, transaction id' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>sr. no</th>
                                            <th>job id</th>
                                            <th>user name</th>
                                            <th>transaction id</th>
                                            <th>phone no.</th>
                                            <th>email</th>
                                            <th>payment type</th>
                                            <th>date and time</th>
                                            <th>amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itemList?.length > 0 ? itemList?.map((item, i) => (
                                            <tr key={i}>
                                                <td data-label="sr. no">{(pageOffset) * pageLimit + i + 1}</td>
                                                <td data-label="job id">{item?.job_id || "-"}</td>
                                                <td data-label="user name">{item?.user_info?.first_name || "-"} {item?.user_info?.last_name || ""}</td>
                                                <td data-label="transaction id">{item?.transaction_id || "-"}</td>
                                                <td data-label="phone no.">{(item?.user_info?.phone && item?.user_info?.country_code && (getDecodedCountryCode(item?.user_info?.country_code) + " " + phoneNumberAutoFormat(item?.user_info?.phone?.replaceAll(/["' ]/g, "")))) || "-"}</td>
                                                <td data-label="email">{item?.user_info?.email || "-"}</td>
                                                <td data-label="payment type">{intToBool(item?.job_info?.payment_status) && intToBool(item?.job_info?.payment_status) ? "job searches" : !intToBool(item?.job_info?.payment_status) && intToBool(item?.job_info?.payment_status) ? "feedback" : "-"}</td>
                                                <td data-label="date and time">{(item?.payment_date && (moment.utc(item?.payment_date).local().format("MMM DD, YYYY hh:mm a"))) || "-"}</td>
                                                <td data-label="amount">{(item?.total_payment_amount && (priceFormat(item?.total_payment_amount))) || "-"}</td>
                                            </tr>
                                        )) : <NoRecordsFound />}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                        </div>
                    </div>
                </AdminLayout>
            )}
        </>

    );
}

export default InvoiceList;