import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { useAddLookupsMutation, useGetDepartmentListSingleMutation } from '../../rtk/services/lookupsApi';
import { toast } from 'react-toastify';
import ModalLayout from '../layouts/modalLayout';
import Loader from '../loader';
import Input from '../helper/Input';

const initialValues = {
    industry_name: "",
    seniority_type: "",
    checkBoxes: [],
    role_name: "",
};
const validationSchema = Yup.object().shape({
    industry_name: Yup.string().required("industry name is required. please enter your industry name."),
    seniority_type: Yup.string().required("seniority type is required. please select your seniority type."),
    checkBoxes: Yup.array().min(1, "please select departments.").required("please select departments."),
    role_name: Yup.string().required("role name is required. please enter your role name."),
});

function AddIndustryModal(props) {
    const [itemList, setItemList] = useState([]);
    const [formData, setFormData] = useState(initialValues);
    const [getList, { data: resItems, isLoading: resItemsLoading, isSuccess: resItemsSuccess }] = useGetDepartmentListSingleMutation();
    const [addLookUps, { data: resData, isSuccess: resSuccess, isLoading: resFetching, error: resError }] = useAddLookupsMutation();

    const handleSubmit = (values) => {
        let payload = {
            industry_uuid: "",
            industry_name: values?.industry_name,
            seniority_type: values?.seniority_type === "one" ? 1 : 0,
            department_id: values?.checkBoxes?.map((elm) => { return JSON.parse(elm) }),
            role_id: "",
            role_name: values?.role_name,
        };
        addLookUps(payload);
    }
    useEffect(() => {
        if (resItemsSuccess) {
            setItemList(resItems?.data);
        }
    }, [resItemsSuccess, resItems]);
    useEffect(() => {
        getList({ page_limit: 1000 });
    }, [getList]);
    useEffect(() => {
        if (resSuccess) {
            toast.success(resData?.message);
            props.action();
            props.updateList();
        }
    }, [resSuccess, resError, resData?.message, props])
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title}>
            {(resFetching || resItemsLoading) && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                    setFieldValue
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Input type="text" name="industry_name" placeholder="Industry Name" />
                            <label htmlFor='name'>Seniority Type</label>
                            <div className='text-group'>
                                <div className="row">
                                    <div className="col-lg-auto">
                                        <div className="custom_checkbox">
                                            <label htmlFor="Industrial">
                                                <Field type="radio" id="Industrial" className="form-check-input me-2" name="seniority_type" value={"zero"} />
                                                <span>Industrial</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-auto">
                                        <div className="custom_checkbox">
                                            <label htmlFor="Medical">
                                                <Field type="radio" id="Medical" className="form-check-input me-2" name="seniority_type" value={"one"} />
                                                <span>Medical</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ErrorMessage name="seniority_type" component="div" className='invalid-feedback d-block' />
                            </div>
                            <label htmlFor='departments'>Select Departments</label>
                            <div className="min-height-form mb-3">
                                {itemList?.length > 0 ? itemList?.map((elm, i) => (
                                    <div className="custom_checkbox" key={i}>
                                        <label htmlFor={`active-${elm?.id}`}>
                                            <input
                                                type="checkbox"
                                                id={`active-${elm?.id}`}
                                                className="form-check-input me-2"
                                                name={`checkBoxes`}
                                                value={elm?.id}
                                                checked={values.checkBoxes?.includes(elm?.id)}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    const id = elm?.id;
                                                    if (isChecked) {
                                                        // If checked, add ID to the array
                                                        setFieldValue("checkBoxes", [...values.checkBoxes, id]);
                                                    } else {
                                                        // If unchecked, remove ID from the array
                                                        setFieldValue(
                                                            "checkBoxes",
                                                            values.checkBoxes.filter((item) => item !== id)
                                                        );
                                                    }
                                                }}
                                            />
                                            <span>{elm?.title || ""}</span>
                                        </label>
                                    </div>
                                )) : "No Departments Found"}
                            </div>
                            <ErrorMessage name="checkBoxes" component="div" className="invalid-feedback d-block mb-3" />
                            <label htmlFor='role'>enter your role name</label>
                            <Input type="text" name="role_name" placeholder="Role Name" />
                            <div className='d-flex justify-content-end gap-10'>
                                <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                <button type='submit' className='btn btn-primary'>{props.currentItem !== null ? "Edit" : "Add"}</button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout >
    );
}

export default AddIndustryModal;