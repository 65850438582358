import React from 'react';
import ModalLayout from '../layouts/modalLayout';
import * as Yup from "yup";
import { Formik } from "formik";
import { useState } from 'react';
import { useEffect } from 'react';
import Input from '../helper/Input';
import { useUpdatePriceMutation } from '../../rtk/services/priceApi';
import Loader from '../loader';

const initialValues = {
    type: "",
    amount: 0,
};

const validationSchema = Yup.object().shape({
    type: Yup.string(),
    amount: Yup.number().nonNullable().required("amount is required. please enter your amount."),
});

function AddEditPricingModal(props) {
    const currentItem = props.currentItem;
    const [formData, setFormData] = useState(initialValues);
    const [updatePrice, priceResponse] = useUpdatePriceMutation();
    const handleSubmit = async (values) => {
        await updatePrice({
            // list_type: currentItem?.type || props?.type?.trim(),
            id: currentItem?.id,
            amount: parseInt(values?.amount)
        })
    }
    useEffect(() => {
        if (currentItem) {
            let obj = {
                type: currentItem?.price_type_value?.replaceAll(/_|ideal|candidate/g, " ")?.trim(),
                amount: currentItem?.price_type_value_amount,
            }
            setFormData(obj);
        }
    }, [currentItem, props]);
    useEffect(() => {
        if (priceResponse.isSuccess) {
            priceResponse.reset();
            props.updateList();
            props.action();
        }
    }, [priceResponse, priceResponse.isSuccess, props]);
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title}>
            {(priceResponse.isLoading) && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Input type="text" name="type" placeholder="type" customClass="text-lowercase" readOnly={true} />
                            <Input type="text" name="amount" placeholder="fee($)" />
                            <div className='d-flex justify-content-end gap-10'>
                                <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                <button type='submit' className='btn btn-primary'>{props.currentItem !== null ? "update" : "Add"}</button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout>
    );
}

export default AddEditPricingModal;