import React, { useCallback, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import BackButton from '../../../../components/helper/backButton';
import AddEditRoleExperienceModal from '../../../../components/modals/AddEditRoleExperienceModal';
import { useGetHiringQuestionSingleMutation } from '../../../../rtk/services/lookupsApi';
import { useEffect } from 'react';
import NoRecordsFound from '../../../../components/shared/NoRecordsFound';
import Loader from '../../../../components/loader';
import { useModuleAccess } from '../../../../helper';
import { constants } from '../../../../constants';

function HiringRoleExperience(props) {
    const [itemList, setItemList] = useState([]);
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Read);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Edit);
    const [addEditModal, setAddEditModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [getList, { data: resData, isLoading: resLoading, isSuccess: resSuccess }] = useGetHiringQuestionSingleMutation();
    const getItemList = useCallback(() => {
        getList({
            hiring_type: "role-experience"
        });
    }, [getList]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
        }
    }, [resSuccess, resData]);
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resLoading && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">role experience</h5>
                            <div className="action_btns">
                                <BackButton />
                            </div>
                        </div>
                        <div className="card-body pb-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>sr. no.</th>
                                            <th>role experience</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ? itemList?.map((item, i) => (
                                                <tr key={i}>
                                                    <td data-label="sr. no.">{i + 1}</td>
                                                    <td data-label="role experience">{item?.role_experience || "-"}</td>
                                                    {hasEditPrivilege && (
                                                        <td data-label="action">
                                                            <div className="action_wrap">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(item); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </div>
                                                        </td>)}
                                                </tr>
                                            ))
                                                :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {addEditModal && (<AddEditRoleExperienceModal state={addEditModal}
                        action={() => { setAddEditModal(false); setCurrentItem(null) }} title={`${currentItem !== null ? "Edit" : "Add"} role experience`} currentItem={currentItem} updateList={() => getItemList()} />)}
                </AdminLayout>
            )}
        </>

    );
}

export default HiringRoleExperience;