import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import HmJobInfo from '../components/hm-job-info';
import JsJobTabs from '../components/js-job-tabs';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useGetJSPostInterviewFeedbackMutation } from '../../../../rtk/services/userApi';
import { useEffect } from 'react';
import Loader from '../../../../components/loader';
import { intToBool } from '../../../../helper';

function JsJobFeedback(props) {
    const { currentHmMatch, currentHmJob } = useSelector(({ common }) => common);
    const [item, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useGetJSPostInterviewFeedbackMutation();
    useEffect(() => {
        getList({
            job_id: currentHmJob?.job_id,
            history_id: parseInt(currentHmMatch?.user_job_history_id),
            user_type: 0
        });
    }, [getList, currentHmMatch]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <HmJobInfo>
                <JsJobTabs />
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="card-title d-flex align-items-center">
                            candidate {currentHmMatch?.js_user_unique_name || "-"} - {currentHmMatch?.js_first_name || "-"} {currentHmMatch?.js_last_name}
                            <span className="badge rounded-pill bg-dark ms-2"><i className="bi-star text-warning me-2" />{currentHmMatch?.js_reliability_rating}</span>
                        </h5>
                    </div>
                    <div className="card-body pb-0">
                        {/*  */}
                        {item?.length > 0 && (
                            <div className="card mb-0">
                                <div className="card-header">
                                    <h5 className="card-title">Feedback by job seeker</h5>
                                </div>
                                <div className="card-body">
                                    {/*  */}
                                    {item?.map((elm, i) => (
                                        <div className={`row ${i !== item.length - 1 ? 'mb-4' : ''}`} key={i}>
                                            <div className="col-auto">
                                                <div className="custom_checkbox">
                                                    <label htmlFor="titleOne" className='pe-0'>
                                                        <input type="checkbox" id="titleOne" className="form-check-input me-2" defaultChecked={true} />
                                                        <span />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <h6 className='text-white mb-2'>{elm?.question || "-"}</h6>
                                                <p className='text-white mb-0'>{elm?.response_option || "-"}</p>
                                                {elm?.sub_options?.length > 0 && (
                                                    <div className="row mt-3">
                                                        {elm?.sub_options?.map((sub, j) => (
                                                            <div key={j} className="col-auto">
                                                                <div className="custom_checkbox">
                                                                    <label className=' px-3 mb-0'>
                                                                        <input type="checkbox" className="form-check-input me-2" defaultChecked={true} />
                                                                        <span>{sub?.sub_option_text || "-"}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    {/*  */}
                                </div>
                            </div>
                        )}
                        {item?.length == 0 && (<div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div>)}
                        {/*  */}
                    </div>
                </div>
            </HmJobInfo>
        </AdminLayout>
    );
}

export default JsJobFeedback;