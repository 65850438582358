import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { Formik } from "formik";
import { useUpdateWorkExperienceMutation } from '../../rtk/services/lookupsApi';
import { toast } from 'react-toastify';
import ModalLayout from '../layouts/modalLayout';
import Loader from '../loader';
import Input from '../helper/Input';

const initialValues = {
    title: "",
};
const validationSchema = Yup.object().shape({
    title: Yup.string().required("total work experience is required. please enter your total work experience."),
});

function AddEditTotalWorkExperienceModal(props) {
    const [formData, setFormData] = useState(initialValues);
    const [addEditItem, { data: resData, isSuccess: resSuccess, isLoading: resFetching, error: resError }] = useUpdateWorkExperienceMutation();
    useEffect(() => {
        if (props.currentItem) {
            let obj = {
                title: props.currentItem?.experience,
            }
            setFormData(obj);
        }
    }, [props.currentItem]);
    const handleSubmit = (values) => {
        let payload = {};
        if (props.currentItem) {
            payload.id = props.currentItem?.id;
            payload.experience = values?.title;
        }
        addEditItem(payload);
    }
    useEffect(() => {
        if (resSuccess) {
            toast.success(resData?.message);
            props.action();
            props.updateList();
        }
    }, [resSuccess, resError, resData?.message, props])
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title}>
            {resFetching && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Input type="text" name="title" placeholder="title" />
                            <div className='d-flex justify-content-end gap-10'>
                                <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                <button type='submit' className='btn btn-primary'>{props.currentItem !== null ? "Edit" : "Add"}</button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout >
    );
}

export default AddEditTotalWorkExperienceModal;