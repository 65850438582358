import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { constants } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useGetDashboardInfoMutation } from '../../../rtk/services/dashboardApi';
import { setDashboardData } from '../../../rtk/feature/commonSlice';
import Loader from '../../../components/loader';
import ChartOne from './components/ChartOne';
import ChartTwo from './components/ChartTwo';
import ChartThree from './components/ChartThree';
import ChartFour from './components/ChartFour';
import ChartFive from './components/ChartFive';
import SharedWidgets from '../../../components/shared/sharedWidgets';
import { modulesPrivilege, useModuleAccess } from '../../../helper';

function Dashboard(props) {
    const dispatch = useDispatch();
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Dashboard, constants.privilegeType.Read);
    const [status, setStatus] = useState("");
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching, error: resError }] = useGetDashboardInfoMutation();
    useEffect(() => {
        getList({ filter_type: status });
    }, [getList, status]);
    useEffect(() => {
        if (resSuccess) {
            dispatch(setDashboardData(resData))
        }
    }, [resData, resError, resSuccess, dispatch]);
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resFetching && (<Loader />)}
                    <div className="card has_col">
                        <div className="card-header">
                            <h5 className="card-title">Filter</h5>
                        </div>
                        <div className="card-body">
                            <div className="row align-items-end">
                                <div className="col-xl-4 col-md-6">
                                    <div className="form-group select-option">
                                        <select className="form-control form-select" value={status} onChange={(e) => { setStatus(e.target.value) }}>
                                            <option value="" defaultValue="">Select</option>
                                            {constants.dashboardFilter?.map((item, i) => (
                                                <option value={item?.value} key={i}>{item?.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SharedWidgets title={"Dashboard"} show={10} moduleFor="dashboard" />
                    {/* <ChartOne title={"Hiring manager and job seekers onboarded new vs total"} data={""} />
            <ChartTwo title={"Job searches made vs job matches made vs hm accepted vs js accepted"} data={""} />
            <ChartThree title={"Job searches made vs job matches made vs hm rejected vs js rejected"} data={""} />
            <ChartFour title={"Total matches accepted and completed vs rejected vs never matched"} data={""} />
            <ChartFive title={"Total revenue made"} data={""} /> */}
                </AdminLayout >
            )}
        </>
    );

}

export default Dashboard;