import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import HmJobInfo from '../components/hm-job-info';
import HmJobTabs from '../components/hm-job-tabs';
import { useLocation } from 'react-router-dom';
import { useUnpaidReasonsListMutation } from '../../../../rtk/services/userApi';
import Loader from '../../../../components/loader';

function HmJobUnpaid(props) {
    const [item, setItem] = useState(null);
    const location = useLocation();
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUnpaidReasonsListMutation();
    useEffect(() => {
        getList({ job_id: location.search?.split("=")?.[1] });
    }, [getList && location.search]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data);
        }
    }, [resSuccess, resData?.data])
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <HmJobInfo>
                <HmJobTabs />
                {item?.length > 0 ? (
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">job unpaid reasons by the hiring manager</h5>
                        </div>
                        <div className="card-body pb-0">
                            <ul className='row'>
                                {item?.map((elm, i) => (
                                    <li key={i} className='col-md-6'>
                                        <div className="custom_checkbox">
                                            <label htmlFor={elm?.unpaid_reason_title?.replaceAll(" ", "")} className='mb-3'>
                                                <input type="checkbox" id={elm?.unpaid_reason_title?.replaceAll(" ", "")} className="form-check-input me-2" checked />
                                                <span>{elm?.unpaid_reason_title || "-"}</span>
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : <div className='col-12'><div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div></div>}
            </HmJobInfo>
        </AdminLayout>
    );
}

export default HmJobUnpaid;