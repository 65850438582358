import React, { useCallback, useEffect } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useGetUserPermissionMutation } from '../../../rtk/services/adminUserApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { useModuleAccess } from '../../../helper';
import { constants } from '../../../constants';

function AdminRoles(props) {
    const [itemList, setItemList] = useState([]);
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Administrative_Panel, constants.privilegeType.Read);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Administrative_Panel, constants.privilegeType.Edit);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetUserPermissionMutation();
    const getItemList = useCallback(() => {
        getList();
    }, [getList]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
        }
    }, [resData, resSuccess]);
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resLoading && (<Loader />)}
                    <div className="card has_col">
                        <div className="card-header">
                            <h5 className="card-title">role management</h5>
                        </div>
                        <div className="card-body pb-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>role name</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ? itemList?.map((item, i) => (
                                                <tr key={i}>
                                                    <td data-label="role name">{item?.role_name || "-"}</td>
                                                    {hasEditPrivilege && (
                                                        <td data-label="action">
                                                            <div className="action_wrap">
                                                                <Link to={"/admin/role/edit/" + item?.user_role_id + "/" + item?.role_name} className="btn btn-icon">
                                                                    <i className="bi-pencil" />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                                :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            )}
        </>

    );
}

export default AdminRoles;