import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useGetSkillsScoreKeyMutation } from '../../../rtk/services/skillsApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { intToBool, useModuleAccess } from '../../../helper';
import { constants } from '../../../constants';

function SoftSkillScoreKey(props) {
    const [itemList, setItemList] = useState([]);
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Manage_Skill_Quiz, constants.privilegeType.Read);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetSkillsScoreKeyMutation();
    useEffect(() => {
        getList();
    }, [getList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
        }
    }, [resData, resSuccess]);
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resLoading && (<Loader />)}
                    <div className="card has_col">
                        <div className="card-header">
                            <h5 className="card-title">scoring key</h5>
                        </div>
                        <div className="card-body pb-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>scoring key</th>
                                            <th>most like me points</th>
                                            <th>empty state points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ? itemList?.map((item, i) => (
                                                <tr key={i}>
                                                    <td data-label="scoring key">{intToBool(item?.scoring_key) ? "plus (+)" : "minus (-)"}</td>
                                                    <td data-label="most like me points">{item?.selected_score || "-"}</td>
                                                    <td data-label="empty state points">{item?.not_selected_score || "-"}</td>
                                                </tr>
                                            ))
                                                :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            )}
        </>
    );
}

export default SoftSkillScoreKey;