import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { Alert } from 'react-bootstrap';
import { createSearchParams, Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetSoftSkillsUserDetailsMutation } from '../../../rtk/services/softSkillsApi';
import { encrypt, encryptKeys, getStatusType, getUserType, intToBool, useModuleAccess, userTypeFunction } from '../../../helper';
import { constants } from '../../../constants';
import Loader from '../../../components/loader';
import moment from 'moment';

function SoftSkillsListingList(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Soft_skills_answer, constants.privilegeType.Read);
    const { userId, job_id, js_history_id, user_type } = useParams();
    const [user, setUser] = useState(null);
    const [inviteUser, setInviteUser] = useState(null);
    const [itemList, setItemList] = useState([]);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetSoftSkillsUserDetailsMutation();
    useEffect(() => {
        getList({
            job_id: job_id,
            js_history_id: js_history_id,
            user_id: userId,
            user_type: user_type,
        });
    }, [getList]);
    useEffect(() => {
        if (resSuccess) {
            setUser(resData?.data?.user?.find((elm) => { return elm?.is_invitee_user == 0 }));
            setInviteUser(resData?.data?.user?.find((elm) => { return elm?.is_invitee_user == 1 }));
            const groupedData = resData?.data?.soft_skill?.reduce((result, elm) => {
                const domainName = elm.domain_name?.replaceAll("\r\n", "");
                const facetName = elm.facet_name?.replaceAll("\r\n", "");
                const existingDomain = result.find(item => item.domainName?.replaceAll("\r\n", "") === domainName);

                if (existingDomain) {
                    const existingFacet = existingDomain.facetNames.find(facet => facet.facetName?.replaceAll("\r\n", "") === facetName);
                    if (existingFacet) {
                        existingFacet.card_score += elm.card_score; // Assuming score is a numeric value
                    } else {
                        existingDomain.facetNames.push({ facetName, score: elm.card_score });
                    }
                } else {
                    result.push({
                        domainName,
                        facetNames: [{ facetName, score: elm.card_score }]
                    });
                }

                return result;
            }, []);
            setItemList(groupedData);
        }
    }, [resSuccess])
    const goBack = () => {
        if (location.search?.includes("softSkillDetail")) {
            navigate(`/soft-skills-listing${location?.search}`);
        } else {
            navigate(-1);
        }
    }
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resLoading && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <button type="button" className="btn btn-primary ms-0" onClick={() => goBack()}>
                                <i className="bi bi-arrow-left" /> Back
                            </button>
                        </div>
                        <div className="card-body">
                            <div role="alert" className="fade mb-0 alert alert-dark show">
                                <div className="alert-heading h4">{user?.first_name || "-"} {user?.last_name || "-"} profile Details </div>
                                <p className="mb-0">
                                    <span className="badge rounded-pill bg-dark me-2">
                                        <i className="bi-star text-warning me-2" />
                                        {userTypeFunction(user?.user_type_Id) ? user?.hm_reliability_rating : user?.js_reliability_rating}
                                    </span>
                                    <span className={`badge rounded-pill me-2 ${intToBool(user?.is_active) ? "bg-success" : "bg-danger"}`} >{getStatusType(user?.is_active)}</span>
                                    <span className="badge rounded-pill bg-dark">
                                        {parseInt(user_type) === constants.userTypeId.js ? 'Job Seeker' : parseInt(user_type) === constants.userTypeId.hm ? 'Hiring Manager' : parseInt(user_type) === constants.userTypeId.guest ? 'Invitee' : 'Both'}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`${parseInt(user_type) === constants.userTypeId.hm && "card "}`}>
                        {parseInt(user_type) === constants.userTypeId.hm && (
                            <div className="card-header">
                                <h5 className="card-title">
                                    job {job_id} - {user?.job_title}
                                    <i className="bi bi-clipboard-fill isCopy text-white ms-2 cursor-pointer" />
                                </h5>
                                <div>
                                    {intToBool(user?.job_is_active) && moment.utc(user?.job_trial_expired_date).local().diff(moment(), "seconds") > 0 && (<span className='badge rounded-pill ms-2'>active</span>)}
                                    {(intToBool(user?.job_is_active) && moment.utc(user?.job_trial_expired_date).local().diff(moment(), "seconds") < 0) || intToBool(user?.job_is_expired) && (<span className='badge bg-danger rounded-pill ms-2'>expired</span>)}

                                    {intToBool(user?.payment_status) && (<span className='badge bg-info rounded-pill  ms-2'>paid full</span>)}
                                    {(intToBool(user?.feedback_payment_status) && !intToBool(user?.payment_status)) && (<span className='badge bg-info rounded-pill  ms-2'>paid feedback</span>)}
                                    {(!intToBool(user?.feedback_payment_status) && !intToBool(user?.payment_status) && moment.utc(user?.job_trial_expired_date).local().isSameOrAfter()) && (<span className='badge bg-danger rounded-pill ms-2'>unpaid</span>)}
                                    {(!intToBool(user?.feedback_payment_status) && !intToBool(user?.payment_status) && !moment.utc(user?.job_trial_expired_date).local().isSameOrAfter()) && (<span className='badge bg-danger rounded-pill ms-2'>expired</span>)}
                                </div>
                            </div>
                        )}
                        <div className={`${parseInt(user_type) === constants.userTypeId.hm && "card-body "}`}>
                            <div className={`${parseInt(user_type) === constants.userTypeId.hm && "card-body px-0 pb-0"}`}>
                                <div className="card mb-0">
                                    <div className="card-header">
                                        {
                                            parseInt(user_type) === constants.userTypeId.hm ? <h5 className="card-title">soft skills test - self</h5>
                                                : parseInt(user_type) === constants.userTypeId.guest ? <h5 className="card-title">soft skills test - invitee</h5>
                                                    : <h5 className="card-title">test date: {moment.utc(resData?.data?.soft_skill?.[0]?.created_at).local().format("DD MMM YYYY")}</h5>
                                        }

                                        <div className="action_btns">
                                            <Link
                                                className="btn btn-primary"
                                                to={`/soft-skills-listing/detail/${user?.user_type_Id == 1 ? job_id : user?.uuid}?currentUser=${new createSearchParams({ ...user, userType: user_type })}&prevFilter=${userId + "/" + job_id + "/" + js_history_id + "/" + user_type + location.search}`}
                                            >
                                                see details soft skills test
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-body pb-0">
                                        {(parseInt(user_type) === constants.userTypeId.guest) && (
                                            <Alert variant="dark" className='payment-card'>
                                                <Alert.Heading className='d-flex justify-content-between mb-3 h6'>
                                                    <p className='mb-0'>invitee</p>
                                                </Alert.Heading>
                                                <div className='row'>
                                                    <div className="col-lg-6">
                                                        <div className='row align-items-center mb-3'>
                                                            <div className="col-lg-3">Name</div>
                                                            <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{inviteUser?.first_name || "-"} {inviteUser?.last_name || "-"}</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className='row align-items-center mb-3'>
                                                            <div className="col-lg-3">Seniority Level</div>
                                                            <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{inviteUser?.seniority_level || "-"}</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className='row align-items-center mb-3 mb-lg-0'>
                                                            <div className="col-lg-3">Email Address</div>
                                                            <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{inviteUser?.work_email || inviteUser?.personal_email || "-"}</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className='row align-items-center'>
                                                            <div className="col-lg-3">Job title</div>
                                                            <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{inviteUser?.job_title || "-"}</span></div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6">
                                                        <div className='row align-items-center'>
                                                            <div className="col-lg-3">Phone Number</div>
                                                            <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{user?.mobile_number || user?.personal_number || "-"}</span></div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </Alert>
                                        )}
                                        {itemList?.map((elm, i) => (
                                            <div className="card mb-3" key={i}>
                                                <div className="card-header">
                                                    <h5 className="card-title">Domain:&nbsp;{elm?.domainName || "-"}</h5>
                                                </div>
                                                <div className="card-body pb-0">
                                                    <div className="table-responsive">
                                                        <div className="inner" />
                                                        <table className="table table-striped no_wrap">
                                                            <thead>
                                                                <tr>
                                                                    <th>Facet Name</th>
                                                                    <th>Score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {elm?.facetNames?.map((facet, i) => (
                                                                    <tr key={i}>
                                                                        <td data-label="Facet Name">{facet?.facetName || "-"}</td>
                                                                        <td data-label="Score">{facet?.score || "-"}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {itemList?.length === 0 && (<div className='col-12'><div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div></div>)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AdminLayout >
            )
            }
        </>
    );
}

export default SoftSkillsListingList;