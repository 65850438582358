import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import HmJobInfo from '../components/hm-job-info';
import JsJobTabs from '../components/js-job-tabs';
import { useCandidateJobDetailMutation, useCandidateRefineDetailMutation } from '../../../../rtk/services/userApi';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Loader from '../../../../components/loader';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { intToBool, priceFormat, roundFigureData } from '../../../../helper';
import { Col, Row } from 'react-bootstrap';
import { constants } from '../../../../constants';

function JsJobDetails(props) {
    const { job_uuid } = useParams();
    const [item, setItem] = useState([]);
    const [itemRefine, setItemRefine] = useState([]);
    const { currentHmMatch } = useSelector(({ common }) => common);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useCandidateJobDetailMutation();
    const [getRefineList, { data: refineData, isSuccess: refineSuccess }] = useCandidateRefineDetailMutation();

    useEffect(() => {
        getList({ job_history_id: job_uuid });
        getRefineList({ job_history_id: job_uuid });
    }, [job_uuid, getList, getRefineList]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data?.[0]);
        }
    }, [resData, resSuccess]);
    useEffect(() => {
        if (refineSuccess) {
            setItemRefine(refineData?.data);
        }
    }, [refineData, refineSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <HmJobInfo>
                <JsJobTabs />
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="card-title d-flex align-items-center">
                            candidate {currentHmMatch?.js_user_unique_name || "-"} - {currentHmMatch?.js_first_name || "-"} {currentHmMatch?.js_last_name}
                            <span className="badge rounded-pill bg-dark ms-2"><i className="bi-star text-warning me-2" />{currentHmMatch?.js_reliability_rating}</span>
                        </h5>
                        <div className="action_btns">
                            hard skills score <span className="badge rounded-pill bg-info ms-2">{currentHmMatch?.hard_skill_percentage || "0"}%</span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Job history Details</h5>
                            </div>
                            <div className="card-body details_style">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>most recent employer</label>
                                            <p className="mb-0">{item?.recent_employer || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>experienced</label>
                                            <p className="mb-0">{intToBool(item?.is_experienced) ? "yes" : "no" || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>company type</label>
                                            <p className="mb-0">{item?.company_type?.company_type || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>company size</label>
                                            <p className="mb-0">{item?.company_type?.company_size || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>industry</label>
                                            <p className="mb-0">{item?.industry_info?.industry || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>department</label>
                                            <p className="mb-0">{item?.department_info?.department_title || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group no-detail-style mb-0">
                                            <label>seniority level</label>
                                            <p className="mb-0">{item?.level?.seniority_level || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group no-detail-style mb-0">
                                            <label>role</label>
                                            <p className="mb-0">{item?.custom_role ? item?.custom_role : item?.role_id?.title || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group no-detail-style mb-0">
                                            <label>responsibility</label>
                                            <p className="mb-0">{item?.level?.responsibility_level || "-"} {item?.level?.sub_responsibility_title && (<span>{'> ' + item?.level?.sub_responsibility_title}</span>)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">relevant experience details</h5>
                            </div>
                            <div className="card-body details_style">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-group">
                                            <label>software languages</label>
                                            <p className="mb-0 comma-list">{item?.software_info?.length > 0 ? item?.software_info?.map((elm, i) => (<span key={i}>{elm?.software_detail_info?.title || "-"}</span>)) : "none"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>role experience</label>
                                            <p className="mb-0">{item?.relevant_experience?.role_experience || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>total work experience</label>
                                            <p className="mb-0">{item?.relevant_experience?.total_work_experience || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>education level</label>
                                            <p className="mb-0">{item?.relevant_experience?.education_level || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>patents</label>
                                            <p className="mb-0">{intToBool(item?.is_patents) ? "yes" : "no"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>certificates in place</label>
                                            <p className="mb-0">{intToBool(item?.is_published_research) ? "yes" : "no"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>veteran</label>
                                            <p className="mb-0">{intToBool(item?.is_veteran) ? "yes" : "no"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group no-detail-style mb-0">
                                            <label>security clearance</label>
                                            <p className="mb-0">{intToBool(item?.is_security_clearance) ? "yes" : "no"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-0">
                            <div className="card-header">
                                <h5 className="card-title">refine search</h5>
                            </div>
                            <div className="card-body details_style">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>job search title</label>
                                            <p className="mb-0">{itemRefine?.job_search_title || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>company type</label>
                                            <p className="mb-0">{itemRefine?.company_info?.company_type || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>company size</label>
                                            <p className="mb-0">{itemRefine?.company_info?.company_size || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>industry</label>
                                            <p className="mb-0">{itemRefine?.industry_info?.industry || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>department</label>
                                            <p className="mb-0">{itemRefine?.department_info?.department_title || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>seniority level</label>
                                            <p className="mb-0 comma-list" >{itemRefine?.seniority?.map((elm, i) => (<span key={i}>{elm?.level}</span>))}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>responsibility</label>
                                            <p className="mb-0">{itemRefine?.responsiblity_info?.title || "-"}  {itemRefine?.responsiblity_info?.sub_responsibility_title && (<span>{'> ' + itemRefine?.responsiblity_info?.sub_responsibility_title}</span>)}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>working style</label>
                                            <p className="mb-0">{itemRefine?.job_details?.working_cycle || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-group">
                                            <label>location</label>
                                            <>
                                                {itemRefine?.location_info?.length > 0 ? itemRefine?.location_info?.map((elm, i) => (
                                                    <Row key={i}>
                                                        {intToBool(elm.is_country) && (<Col sm={"auto"} key={i}>{elm?.country}</Col>)}
                                                        {intToBool(elm.is_state) && (<Col sm={"auto"} key={i}>{elm?.state}, {elm?.country}</Col>)}
                                                        {intToBool(elm.is_city) && (
                                                            <>
                                                                <Col sm={"auto"} key={i}>{elm?.city}, {elm?.state}, {elm?.country}</Col>
                                                                <Col sm={"auto"}><strong>radius</strong> <span className='ms-3' /> {elm?.radius}</Col>
                                                            </>
                                                        )}
                                                    </Row>
                                                )) : "-"}
                                            </>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="text-group">
                                            <label>total compensation</label>
                                            <>
                                                <p className='mb-2'>{priceFormat(itemRefine?.job_details?.total_compensation) || "-"}/{itemRefine?.job_details?.salary_pay_periods}</p>
                                                <div className="row">
                                                    <div className="col-auto"><strong>i'd like to work</strong> <span className='ms-3' />{itemRefine?.job_details?.job_type || "-"}</div>
                                                    <div className="col-auto"><strong>salary</strong> <span className='ms-3' /> {roundFigureData(itemRefine?.job_details?.salary_from_range, itemRefine?.job_details?.salary_to_range)}/{itemRefine?.job_details?.salary_pay_periods}</div>
                                                    <div className="col-auto"><strong>commission</strong> <span className='ms-3' /> {roundFigureData(itemRefine?.job_details?.commission_from_range, itemRefine?.job_details?.commission_to_range)}/{itemRefine?.job_details?.salary_pay_periods}</div>
                                                    <div className="col-auto"><strong>bonus</strong> <span className='ms-3' /> {roundFigureData(itemRefine?.job_details?.bonus_from_range, itemRefine?.job_details?.bonusToRange)}/{itemRefine?.job_details?.salary_pay_periods}</div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="text-group no-detail-style mb-0">
                                            <label>benefits</label>
                                            <p className="mb-0 comma-list">
                                                {itemRefine?.benefits_obj_list?.benefits_obj_list?.map((elm, i) => (
                                                    <>
                                                        {elm?.benefit_id !== constants.otherBenefitId ? <span key={i}>{elm?.title || "-"}</span> : <span key={i}>{itemRefine?.benefits_obj_list?.custom_benefit || "-"}</span>}

                                                    </>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HmJobInfo>
        </AdminLayout>
    );
}

export default JsJobDetails;