import React, { Fragment, useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import HmJobTabs from '../components/hm-job-tabs';
import { useParams } from 'react-router-dom';
import { useUserJobDetailMutation } from '../../../../rtk/services/userApi';
import Loader from '../../../../components/loader';
import { intToBool, priceFormat, roundFigureData } from '../../../../helper';
import { constants } from '../../../../constants';
import HmJobInfo from '../components/hm-job-info';
import { useDispatch } from 'react-redux';
import { setCurrentHmJob } from '../../../../rtk/feature/commonSlice';
import { Col, Row } from 'react-bootstrap';

function HmJobDetail(props) {
    const dispatch = useDispatch();
    const { job_uuid } = useParams();
    const [item, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserJobDetailMutation();
    useEffect(() => {
        getList({
            job_uuid: job_uuid
        });
    }, [getList, job_uuid]);
    useEffect(() => {
        if (resSuccess) {
            dispatch(setCurrentHmJob(resData?.data));
            setItem(resData?.data);
        }
    }, [dispatch, resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <HmJobInfo>
                <HmJobTabs />
                <div className="card-body px-0 pb-0">
                    {/*  */}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Job Details</h5>
                        </div>
                        <div className="card-body details_style">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>hiring goal</label>
                                        <p className="mb-0">{item?.job_details?.hiring_goal || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>department</label>
                                        <p className="mb-0">{item?.department_info?.department_title || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>seniority level</label>
                                        <p className="mb-0">{item?.seniority_info?.seniority_level || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-group">
                                        <label>responsibility</label>
                                        <p className="mb-0">{item?.job_details?.responsibility || "-"} {item?.job_details?.sub_responsibility && (<span>{'> ' + item?.job_details?.sub_responsibility}</span>)}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-group">
                                        <label>role</label>
                                        <p className="mb-0">{item?.job_details?.custom_role ? item?.job_details?.custom_role : item?.job_details?.role || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="text-group">
                                        <label>software languages</label>
                                        <p className="mb-0 comma-list">{item?.software_info?.length > 0 ? item?.software_info?.map((elm, i) => (<span key={i}>{elm?.software_id__title || "-"}</span>)) : "none"}</p>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="text-group">
                                        <label>job location</label>
                                        <>
                                            {item?.location_info?.map((elm, i) => (
                                                <Row key={i}>
                                                    {intToBool(elm.is_country) && (<Col sm={"auto"} key={i}>{elm?.country}</Col>)}
                                                    {intToBool(elm.is_state) && (<Col sm={"auto"} key={i}>{elm?.state}, {elm?.country}</Col>)}
                                                    {intToBool(elm.is_city) && (
                                                        <>
                                                            <Col sm={"auto"} key={i}>{elm?.city}, {elm?.state}, {elm?.country}</Col>
                                                            <Col sm={"auto"}><strong>radius</strong> <span className='ms-3' /> {elm?.radius}</Col>
                                                        </>
                                                    )}
                                                </Row>
                                            ))}
                                        </>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group mb-0">
                                        <label>working style</label>
                                        <p className="mb-0">{item?.ideal_candidate_info?.[0]?.job_info?.working_cycle || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="text-group">
                                        <label>total compensation</label>
                                        <>
                                            <p className='mb-2'>{priceFormat(item?.total_compensation) || "-"}/{item?.units?.salary_units}</p>
                                            <div className="row">
                                                <div className="col-auto"><strong>this job is</strong> <span className='ms-3' /> {constants.JOB_TYPE[item?.job_details?.job_type] || "-"}</div>
                                                <div className="col-auto"><strong>salary</strong> <span className='ms-3' /> {roundFigureData(item?.job_details?.salary_from_range, item?.job_details?.salary_to_range)}/{item?.units?.salary_units}</div>
                                                <div className="col-auto"><strong>commission</strong> <span className='ms-3' /> {roundFigureData(item?.job_details?.commission_from_range, item?.job_details?.commission_to_range)}/{item?.units?.salary_units}</div>
                                                <div className="col-auto"><strong>bonus</strong> <span className='ms-3' /> {roundFigureData(item?.job_details?.bonus_from_range, item?.job_details?.bonusToRange)}/{item?.units?.salary_units}</div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="text-group no-detail-style mb-0">
                                        <label>benefits</label>
                                        <p className="mb-0 comma-list">
                                            {item?.benefits_obj_list?.benefits?.map((elm, i) => (
                                                <>
                                                    {elm?.benefit_id__benefit_id !== constants.otherBenefitId ? <span key={i}>{elm?.benefit_id__title || "-"}</span> : <span key={i}>{item?.benefits_obj_list?.custom_benefit || "-"}</span>}

                                                </>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className="card mb-0">
                        <div className="card-header">
                            <h5 className="card-title">Ideal Candidate</h5>
                        </div>
                        <div className="card-body details_style">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>industry</label>
                                        <p className="mb-0">{item?.ideal_candidate_info?.[0]?.industry_info?.industry || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>company type</label>
                                        <p className="mb-0">{item?.ideal_candidate_info?.[0]?.company_info?.company_type || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>company size</label>
                                        <p className="mb-0">{item?.ideal_candidate_info?.[0]?.company_info?.company_size || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>department</label>
                                        <p className="mb-0">{item?.ideal_candidate_info?.[0]?.department_info?.department_title || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>seniority level</label>
                                        <p className="mb-0 comma-list" >{item?.ideal_candidate_info?.[0]?.seniority_info?.map((elm, i) => (<span key={i}>{elm?.level}</span>))}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>responsibility</label>
                                        <p className="mb-0">{item?.ideal_candidate_info?.[0]?.responsibility?.responsibility || "-"}  {item?.ideal_candidate_info?.[0]?.responsibility?.sub_responsibility_title && (<span>{'> ' + item?.ideal_candidate_info?.[0]?.responsibility?.sub_responsibility_title}</span>)}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>role experience</label>
                                        <p className="mb-0">{item?.ideal_candidate_info?.[0]?.role_experience_info?.role_experience || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>total work experience</label>
                                        <p className="mb-0">{item?.ideal_candidate_info?.[0]?.total_work_experience?.total_experience__title || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>education level</label>
                                        <p className="mb-0">{item?.ideal_candidate_info?.[0]?.education_level?.education_level || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>patents</label>
                                        <p className="mb-0">{intToBool(item?.ideal_candidate_info?.[0]?.is_patents) ? "yes" : "no"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>certificates in place</label>
                                        <p className="mb-0">{intToBool(item?.ideal_candidate_info?.[0]?.is_published_research) ? "yes" : "no"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group">
                                        <label>veteran</label>
                                        <p className="mb-0">{intToBool(item?.ideal_candidate_info?.[0]?.is_veteran) ? "yes" : "no"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-group no-detail-style mb-0">
                                        <label>security clearance</label>
                                        <p className="mb-0">{intToBool(item?.ideal_candidate_info?.[0]?.is_security_clearance) ? "yes" : "no"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                </div>
            </HmJobInfo>
        </AdminLayout>
    );
}

export default HmJobDetail;