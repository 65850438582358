import React, { useCallback, useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { constants } from '../../../constants';
import { useDebounce } from 'use-debounce';
import { useDeleteLookupsMutation, useGetLookupsListMutation } from '../../../rtk/services/lookupsApi';
import { customSwalFire, getSeniorityType, useModuleAccess } from '../../../helper';
import { toast } from 'react-toastify';
import Loader from '../../../components/loader';
import CommonPagination from '../../../components/shared/CommonPagination';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import EditIndustryModal from '../../../components/modals/EditIndustryModal';
import ExportButton from '../../../components/helper/exportButton';
import BackButton from '../../../components/helper/backButton';
import AddIndustryModal from '../../../components/modals/AddIndustryModal';

function IndustryList(props) {
    const [itemList, setItemList] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Read);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Edit);
    const { hasCreatePrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Create);
    const { hasDeletePrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Delete);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [searchKey, setSearchKey] = useState("");
    const [searchValue] = useDebounce(searchKey, 1000);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching, error: resError }] = useGetLookupsListMutation();
    const [deleteItem, { data: deleteData, isSuccess: deleteSuccess, isLoading: deleteFetching, reset: deleteReset }] = useDeleteLookupsMutation();
    const getItemList = useCallback(() => {
        getList({
            search_keyword: searchValue,
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
        });
    }, [searchValue, pageLimit, pageOffset, getList]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resError, resSuccess]);
    const handleActions = (item, type) => {
        let title = "";
        let textMessage = "";
        if (type === constants?.actionTypes?.delete) {
            title = "Delete?"
            textMessage = "Are you sure you want to Delete?"
        }
        customSwalFire(title, textMessage, "question").then((result) => {
            if (result.isConfirmed) {
                deleteItem({ industry_uuid: item?.uuid })
            }
        });
    }
    useEffect(() => {
        if (deleteSuccess) {
            toast.success(deleteData?.message);
            deleteReset();
            getItemList();
        }
    }, [deleteData, deleteReset, deleteSuccess, getItemList]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (location?.search?.includes("type=industry")) {
            const searchParams = new URLSearchParams(location.search);
            const urlSearch = searchParams.get("search");
            const urlPageOffset = searchParams.get("pageOffset");
            setSearchKey(urlSearch);
            setPageOffset(parseInt(urlPageOffset));
        } else {
            getItemList();
        }
    }, [location])
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {(resFetching || deleteFetching) && (<Loader />)}
                    <div className="card has_col">
                        <div className="card-header">
                            <h5 className="card-title">Industry list ({totalCount})</h5>
                            <div className="action_btns">
                                <button type="button" className="btn btn-primary" onClick={(e) => { navigate("/lookups/hiring") }}>
                                    <i className="bi-arrow-left" />
                                    Back
                                </button>
                                {hasReadPrivilege && (
                                    <button type="button" className="btn btn-primary" onClick={() => { setAddModal(true); setCurrentItem(null); }}> <i className="bi bi-plus" /> Add</button>
                                )}
                                <ExportButton type={constants.exportKeys.industries} />
                            </div>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-group">
                                        <input type="text" value={searchKey} className='form-control' placeholder='Search by industry name' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>Sr. no.</th>
                                            <th>Industry Name</th>
                                            <th>Seniority type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ? itemList?.map((item, i) => (
                                                <tr key={i}>
                                                    <td data-label="Sr. no.">{(pageOffset) * pageLimit + i + 1}</td>
                                                    <td data-label="Industry Name"><Link to={`/lookups/department/${item?.uuid}?search=${searchKey}&pageOffset=${pageOffset}&pageLimit=${pageLimit}&type=industry`} className='link-color'>{item?.name || "-"}</Link></td>
                                                    <td data-label="Seniority type">{getSeniorityType(item?.seniority_level) || "-"}</td>
                                                    {(hasEditPrivilege || hasDeletePrivilege) && (
                                                        <td data-label="Action">
                                                            <div className="action_wrap">
                                                                {hasEditPrivilege && (
                                                                    <button type="button" className="btn btn-icon" onClick={(e) => { setEditModal(true); setCurrentItem(item); }}>
                                                                        <i className="bi-pencil" />
                                                                    </button>
                                                                )}
                                                                {hasDeletePrivilege && (
                                                                    <button type="button" className="btn btn-icon" onClick={(e) => handleActions(item, constants?.actionTypes?.delete)}>
                                                                        <i className="bi-trash" />
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                                :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                        </div>
                    </div>
                    {editModal && (<EditIndustryModal state={editModal}
                        action={() => { setEditModal(false); setCurrentItem(null) }} title={`${currentItem !== null ? "Edit" : "Add"} Industry`} currentItem={currentItem} updateList={() => getItemList()} />)}
                    {addModal && (<AddIndustryModal state={addModal}
                        action={() => { setAddModal(false); setCurrentItem(null) }} title={`${currentItem !== null ? "Edit" : "Add"} Industry`} currentItem={currentItem} updateList={() => getItemList()} />)}
                </AdminLayout>
            )}
        </>

    );
}

export default IndustryList;