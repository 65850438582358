import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const exportApi = createApi({
    reducerPath: "exportApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getExportRequests: builder.mutation({
            query: (body) => {
                const url = `page_offset=${body?.page_offset || 1}&page_limit=${body?.page_limit || 10}`;
                return {
                    url: `/admin/export/request?${url}`,
                    method: "get"
                }
            },
        }),
        generateExportRequest: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/export-record`,
                    method: "post",
                    body
                }
            },
        }),
    }),
});

export const {
    useGetExportRequestsMutation,
    useGenerateExportRequestMutation,
} = exportApi;
