import { useEffect } from 'react';
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { useState } from 'react';
import Input from '../helper/Input';
import Select from '../helper/Select';
import { useGetSkillsDomainListMutation, useGetSkillsFacetListMutation } from '../../rtk/services/skillsApi';
import Loader from '../loader';
import useCustomFormValidation, { getModifiedSelectOptions } from '../../helper';

const initialValues = {
    questionText: "",
    questionDomain: "",
    questionFacet: "",
    questionScore: "",
    pairedQuestionText: "",
    pairedQuestionDomain: "",
    pairedQuestionFacet: "",
    pairedQuestionScore: "",
    status: "active",
};
const validationSchema = Yup.object().shape({
    questionText: Yup.string().required("text is required. please enter your text."),
    questionDomain: Yup.string().required("domain name is required. please select your domain name."),
    questionFacet: Yup.string().required("facet name is required. please select your facet name."),
    questionScore: Yup.string().required("scoring key is required. please select your scoring key."),

    pairedQuestionText: Yup.string().required("paired text is required. please enter your text."),
    pairedQuestionDomain: Yup.string().required("paired domain name is required. please select your domain name."),
    pairedQuestionFacet: Yup.string().required("paired facet name is required. please select your facet name."),
    pairedQuestionScore: Yup.string().required("paired scoring key is required. please select your scoring key."),
    status: Yup.string(),
});

function AddSoftSkillsModal({ setChildFormValue, setChildFormStatus }) {
    const [formValue, setFormValue] = useState(null);
    const { isFormValid, validateForm } = useCustomFormValidation(validationSchema);
    const [getDomainList, { data: domainList, isSuccess: domainSuccess, isLoading: domainLoading }] = useGetSkillsDomainListMutation();
    const [getFacetList, { data: facetList, isSuccess: facetSuccess, isLoading: facetLoading }] = useGetSkillsFacetListMutation();
    const [getFacetPairedList, { data: facetPairedList, isSuccess: pairedFacetSuccess, isLoading: facetPairedLoading }] = useGetSkillsFacetListMutation();
    useEffect(() => { getDomainList(); }, [getDomainList]);
    const handleGetFacets = (e) => {
        getFacetList({ domain_id: e.target.value });
    }
    const handleGetSecondFacets = (e) => {
        getFacetPairedList({ domain_id: e.target.value });
    }
    const handleSubmit = (values) => {
        console.log(values)
    }
    useEffect(() => {
        setChildFormStatus(isFormValid);
    }, [formValue])
    const updatedDomainList = getModifiedSelectOptions({ trueState: domainSuccess, data: domainList?.data, value: "id", title: "domain_name" });
    const updatedFacetList = getModifiedSelectOptions({ trueState: facetSuccess, data: facetList?.data, value: "facet_id", title: "facet_name" });
    const pairedFacetList = getModifiedSelectOptions({ trueState: pairedFacetSuccess, data: facetPairedList?.data, value: "facet_id", title: "facet_name" });
    return (
        <>
            {(facetLoading || domainLoading || facetPairedLoading) && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validate={(values) => {
                    setChildFormValue(values);
                    validateForm(values);
                    setFormValue(values);
                }}
            >
                {({
                    values,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5 className='text-white text-center mb-2'>first question details</h5>
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="questionText" className='ms-2'>text</label>
                                            <Input type="textarea" name="questionText" placeholder="text" />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="questionDomain" className='ms-2'>domain name</label>
                                            <Select type="select" name="questionDomain" placeholder="" data={updatedDomainList} onChangeFunc={handleGetFacets} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="questionFacet" className='ms-2'>facet name</label>
                                            <Select type="select" name="questionFacet" placeholder="" data={updatedFacetList} />
                                        </div>
                                        <div className="col-12">
                                            <div className='text-group'>
                                                <label htmlFor="questionScore" className='ms-2'>scoring key</label>
                                                <div className="row">
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="plusChild">
                                                                <Field type="radio" id="plusChild" className="form-check-input me-2" name="questionScore" value={"plus"} />
                                                                <span>plus (+)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="minusChild">
                                                                <Field type="radio" id="minusChild" className="form-check-input me-2" name="questionScore" value={"minus"} />
                                                                <span>minus (-)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="questionScore" component="div" className='invalid-feedback d-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h5 className='text-white text-center mb-2'>paired question details</h5>
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="pairedQuestionText" className='ms-2'>text</label>
                                            <Input type="textarea" name="pairedQuestionText" placeholder="text" />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="pairedQuestionDomain" className='ms-2'>domain name</label>
                                            <Select type="select" name="pairedQuestionDomain" placeholder="" data={updatedDomainList} onChangeFunc={handleGetSecondFacets} />
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="pairedQuestionFacet" className='ms-2'>facet name</label>
                                            <Select type="select" name="pairedQuestionFacet" placeholder="" data={pairedFacetList} />
                                        </div>
                                        <div className="col-12">
                                            <div className='text-group'>
                                                <label htmlFor="pairedQuestionScore" className='ms-2'>scoring key</label>
                                                <div className="row">
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="pairedPlusChild">
                                                                <Field type="radio" id="pairedPlusChild" className="form-check-input me-2" name="pairedQuestionScore" value={"plus"} />
                                                                <span>plus (+)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-auto">
                                                        <div className="custom_checkbox">
                                                            <label htmlFor="pairedMinusChild">
                                                                <Field type="radio" id="pairedMinusChild" className="form-check-input me-2" name="pairedQuestionScore" value={"minus"} />
                                                                <span>minus (-)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="pairedQuestionScore" component="div" className='invalid-feedback d-block' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
}

export default AddSoftSkillsModal;