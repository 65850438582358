import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import UserTabs from '../components/userTabs';
import { Link, useParams } from 'react-router-dom';
import { constants } from '../../../../constants';
import { useUserFeedbackListMutation } from '../../../../rtk/services/userApi';
import CommonPagination from '../../../../components/shared/CommonPagination';
import NoRecordsFound from '../../../../components/shared/NoRecordsFound';
import Loader from '../../../../components/loader';
import { encryptKeys } from '../../../../helper';

function HmJobFeedbacks(props) {
    const { userId } = useParams();
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [totalCount, setTotalCount] = useState(0);
    const [status, setStatus] = useState(0);
    const [itemList, setItemList] = useState([]);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserFeedbackListMutation();
    useEffect(() => {
        getList({
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            user_uuid: userId,
            feedback_stage: status,
        });
    }, [getList, userId, status, pageLimit, pageOffset]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records)
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title col-lg-4">User Job Feedback</h5>
                    <div className="action_btns col-lg-8 my-2">
                        <div className="row justify-content-end">
                            <div className="col-md-4">
                                <div className="form-group mb-0 select-option">
                                    <select className="form-select" onChange={(e) => { setStatus(e.target.value); setPageOffset(0); }}>
                                        {constants.statusFeedbackType.map((item, i) => (
                                            <option value={item.value} key={i}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <UserTabs />
                    <div className="table-responsive">
                        <div className="inner" />
                        <table className="table table-striped no_wrap">
                            <thead>
                                <tr>
                                    <th>Sr no.</th>
                                    <th>Candidate</th>
                                    <th>Job</th>
                                    <th>Feedback stage</th>
                                    <th>action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itemList?.length > 0 ? itemList?.map((item, i) => (
                                        <tr key={i}>
                                            <td data-label="Sr no.">{(pageOffset) * pageLimit + i + 1}</td>
                                            <td data-label="Candidate"><Link to={`/user/feedback/detail/${item?.user_info?.user_uuid || item?.user_uuid}/${item?.job_info?.job_uuid || item?.job_uuid}/${item?.feedback_type}/${item?.job_history_id}?keys=${encryptKeys(item)}`} className='link-color'>{item?.user_info?.user_unique_name || item?.user_unique_name || "-"} - {item?.candidate_first_name} {item?.candidate_last_name}</Link></td>
                                            <td data-label="Job">{item?.job_info?.id || item?.job_id || "-"} - {item?.job_title}</td>
                                            <td data-label="Feedback stage">{item?.feedback_type || "-"}</td>
                                            <td data-label="action">
                                                <div className="action_wrap">
                                                    <Link to={`/user/feedback/detail/${item?.user_info?.user_uuid || item?.user_uuid}/${item?.job_info?.job_uuid || item?.job_uuid}/${item?.feedback_type}/${item?.job_history_id}?keys=${encryptKeys(item)}`} className="btn btn-icon">
                                                        <i className="bi-eye" />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                        :
                                        <NoRecordsFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                </div>
            </div>
        </AdminLayout>
    );
}

export default HmJobFeedbacks;