import React from 'react';
import { numberFormatStyle } from '../../../../helper';

function Widgets({ title, value }) {
    return (
        <div className="col-lg-4 col-sm-6">
            <div className="widget_box card">
                <div className='inner' />
                <h6 className="title" dangerouslySetInnerHTML={{ __html: title }} />
                {numberFormatStyle(value)}
            </div>
        </div>
    );
}

export default Widgets;