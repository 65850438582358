import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useJobSoftSkillMutation } from '../../../../rtk/services/userApi';
import { useEffect } from 'react';
import moment from 'moment';
import NoRecordsFound from '../../../../components/shared/NoRecordsFound';
import Loader from '../../../../components/loader';

function JsSoftSkillDetails(props) {
    const { userId } = useParams();
    const [item, setItem] = useState(null);
    const [itemList, setItemList] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useJobSoftSkillMutation();
    useEffect(() => {
        getList({
            user_uuid: userId
        });
    }, [getList, userId])
    useEffect(() => {
        if (resSuccess) {
            const data = resData?.data?.soft_skill?.[0]?.data;
            setItem(data);
            const mergedData = Object.values(data?.reduce((result, elm) => {
                const { card_number, ...rest } = elm;

                if (!result[card_number]) {
                    // If card_number doesn't exist, create a new entry
                    result[card_number] = { ...rest, cardTwo: {} };
                }

                // Add facet_name to the facet_names object
                result[card_number].cardTwo = elm;

                return result;
            }, {}));
            setItemList(mergedData);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">detailed soft skills test</h5>
                </div>
                <div className="card-body pb-0">
                    {/*  */}
                    <div className="card mb-3">
                        <div className="card-header">
                            <h5 className="card-title">test date : {item?.[0]?.test_date && (moment.utc(item?.[0]?.test_date).local().format("DD MMM YYYY"))}</h5>
                        </div>
                        <div className="card-body pb-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>card number</th>
                                            <th>most like me</th>
                                            <th>domain name</th>
                                            <th>facet name</th>
                                            <th>score</th>
                                            <th>empty state</th>
                                            <th>domain name</th>
                                            <th>facet name</th>
                                            <th>score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itemList?.length > 0 ? itemList?.map((item, i) => (
                                            <tr key={i}>
                                                <td data-label="card number">{i + 1 || 0}</td>
                                                <td data-label="most like me">{item.text}</td>
                                                <td data-label="domain name">{item.domain_name}</td>
                                                <td data-label="facet name">{item.facet_name}</td>
                                                <td data-label="score">{item.score}</td>
                                                <td data-label="empty state">{item.cardTwo.text}</td>
                                                <td data-label="domain name">{item.cardTwo.domain_name}</td>
                                                <td data-label="facet name">{item.cardTwo.facet_name}</td>
                                                <td data-label="score">{item.cardTwo.score}</td>
                                            </tr>
                                        ))
                                            :
                                            <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                </div>
            </div>
        </AdminLayout>
    );
}

export default JsSoftSkillDetails;