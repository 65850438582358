import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { useUpdateTotalCompensationMutation } from '../../rtk/services/lookupsApi';
import { toast } from 'react-toastify';
import ModalLayout from '../layouts/modalLayout';
import Loader from '../loader';
import CurrencyInput from 'react-currency-input-field';

const initialValues = {
    hourly: [0, 0],
    monthly: [0, 0],
    yearly: [0, 0],
};
const validationSchema = Yup.object().shape({
    hourly: Yup.array().test({
        test: function (hourlyArray) {
            const [minHourly, maxHourly] = hourlyArray;
            return parseInt(minHourly) <= parseInt(maxHourly);
        },
        message: "Hourly maximum value must be greater than or equal to hourly minimum value.",
    })
        .required("hourly value is required")
        .test({
            test: function (hourlyArray) {
                const [minHourly, maxHourly] = hourlyArray;
                return parseInt(minHourly) !== 0 && parseInt(maxHourly) !== 0;
            },
            message: "Hourly minimum and hourly maximum values must be greater than 0.",
        }),
    monthly: Yup.array().test({
        test: function (monthlyArray) {
            const [minMonthly, maxMonthly] = monthlyArray;
            return parseInt(minMonthly) <= parseInt(maxMonthly);
        },
        message: "Monthly maximum value must be greater than or equal to monthly minimum value.",
    }).required("monthly value is required")
        .test({
            test: function (monthlyArray) {
                const [minMonthly, maxMonthly] = monthlyArray;
                return parseInt(minMonthly) !== 0 && parseInt(maxMonthly) !== 0;
            },
            message: "Monthly minimum and monthly maximum values must be greater than 0.",
        }),
    yearly: Yup.array().test({
        test: function (yearlyArray) {
            const [minYearly, maxYearly] = yearlyArray;
            return parseInt(minYearly) <= parseInt(maxYearly);
        },
        message: "Yearly maximum value must be greater than or equal to yearly minimum value.",
    }).required("yearly value is required")
        .test({
            test: function (yearlyArray) {
                const [minYearly, maxYearly] = yearlyArray;
                return parseInt(minYearly) !== 0 && parseInt(maxYearly) !== 0;
            },
            message: "Yearly minimum and yearly maximum values must be greater than 0.",
        }),
});

function AddEditTotalCompensationModal(props) {
    const [tabState, setTabState] = useState("hourly")
    const [formData, setFormData] = useState(initialValues);
    const [updateItem, { data: resData, isSuccess: resSuccess, isLoading: resFetching, error: resError }] = useUpdateTotalCompensationMutation()
    useEffect(() => {
        if (props.currentItem) {
            let item = props.currentItem;
            let obj = {
                hourly: [item?.hourly_min || 0, item?.hourly_max || 0],
                monthly: [item?.monthly_min || 0, item?.monthly_max || 0],
                yearly: [item?.yearly_min || 0, item?.yearly_max || 0],
            }
            setFormData(obj);
        }
    }, [props.currentItem]);
    const handleSubmit = async (values) => {
        let payload = {};
        if (props.currentItem) {
            payload.id = props.currentItem?.id;
            payload.hourly_min = JSON.parse(Math.round(values?.hourly?.[0]));
            payload.hourly_max = JSON.parse(Math.round(values?.hourly?.[1]));
            payload.monthly_min = JSON.parse(Math.round(values?.monthly?.[0]));
            payload.monthly_max = JSON.parse(Math.round(values?.monthly?.[1]));
            payload.yearly_min = JSON.parse(Math.round(values?.yearly?.[0]));
            payload.yearly_max = JSON.parse(Math.round(values?.yearly?.[1]));
        }
        await updateItem(payload);
    }
    useEffect(() => {
        if (resSuccess) {
            toast.success(resData?.message);
            props.action();
            props.updateList();
        }
    }, [resSuccess, resError, resData?.message, props])
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title}>
            {resFetching && (<Loader />)}
            <div className="custom_tabs">
                <div className="nav-tabs nav mx-auto">
                    <button type="button" className={`nav-link ${tabState === "hourly" ? "active" : ""}`} onClick={() => { setTabState("hourly") }}>hourly</button>
                    <button type="button" className={`nav-link ${tabState === "monthly" ? "active" : ""}`} onClick={() => { setTabState("monthly") }}>monthly</button>
                    <button type="button" className={`nav-link ${tabState === "yearly" ? "active" : ""}`} onClick={() => { setTabState("yearly") }}>yearly</button>
                </div>
            </div >
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                    setFieldValue,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            {/* hourly */}
                            {tabState === "hourly" && (
                                <>
                                    <div className={`form-group ${values?.hourly[0] > 0 ? "has-value" : ""}`}>
                                        <CurrencyInput
                                            maxLength={9}
                                            name="min"
                                            className="form-control"
                                            placeholder="enter minimum value"
                                            decimalsLimit={2}
                                            allowDecimals={tabState === "hourly"}
                                            allowNegativeValue={false}
                                            value={!tabState === "hourly" || values?.hourly[0] === 0 ? "" : values?.hourly[0]}
                                            prefix="$"
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value?.replaceAll(/[$,]/g, ""))) {
                                                    if (e.target.value && e.target.value?.replaceAll(/[$,]/g, "")?.length < 9) {
                                                        if (tabState === "hourly") {
                                                            let min = e.target.value?.replaceAll(/[$,]/g, "");
                                                            // let max = ((min * 100 / 100) + ((20 * min) / 100));
                                                            setFieldValue("hourly", [min.toString().replace(/(\.\d{2})\d*$/, "$1"), values?.hourly[1].toString().replace(/(\.\d{2})\d*$/, "$1")]);
                                                        } else {
                                                            let min = Number(e.target.value?.replaceAll(/[$,]/g, ""));
                                                            // let max = (min + ((20 * min) / 100));
                                                            setFieldValue("hourly", [min, values?.hourly[1]]);
                                                        }
                                                    } else if (!e.target.value) {
                                                        setFieldValue("hourly", [0, 0]);
                                                    }
                                                }
                                            }}
                                            inputMode="text"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className={`form-group ${values?.hourly[1] > 0 ? "has-value" : ""}`}>
                                        <CurrencyInput
                                            maxLength={9}
                                            name="min"
                                            className="form-control"
                                            placeholder="enter maximum value"
                                            decimalsLimit={2}
                                            allowDecimals={tabState === "hourly"}
                                            allowNegativeValue={false}
                                            value={!tabState === "hourly" || values?.hourly[1] === 0 ? "" : values?.hourly[1]}
                                            prefix="$"
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value?.replaceAll(/[$,]/g, ""))) {
                                                    if (tabState === "hourly") {
                                                        let max = e.target.value?.replaceAll(/[$,]/g, "");
                                                        // let min = ((80 * max) / 100);
                                                        setFieldValue("hourly", [values?.hourly[0].toString().replace(/(\.\d{2})\d*$/, "$1"), max.toString().replace(/(\.\d{2})\d*$/, "$1")]);
                                                    } else {
                                                        let max = Number(e.target.value?.replaceAll(/[$,]/g, ""));
                                                        // let min = ((80 * max) / 100).toFixed(0);
                                                        setFieldValue("hourly", [values?.hourly[0], max]);
                                                    }
                                                }
                                            }}
                                            inputMode="text"
                                            autoComplete="off"
                                        />
                                    </div>
                                </>
                            )}
                            {/* hourly */}
                            {/* monthly */}
                            {tabState === "monthly" && (
                                <>
                                    <div className={`form-group ${values?.monthly[0] > 0 ? "has-value" : ""}`}>
                                        <CurrencyInput
                                            maxLength={9}
                                            name="min"
                                            className="form-control"
                                            placeholder="enter minimum value"
                                            decimalsLimit={2}
                                            allowDecimals={tabState === "monthly"}
                                            allowNegativeValue={false}
                                            value={!tabState === "monthly" || values?.monthly[0] === 0 ? "" : values?.monthly[0]}
                                            prefix="$"
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value?.replaceAll(/[$,]/g, ""))) {
                                                    if (e.target.value && e.target.value?.replaceAll(/[$,]/g, "")?.length < 9) {
                                                        if (tabState === "monthly") {
                                                            let min = e.target.value?.replaceAll(/[$,]/g, "");
                                                            // let max = ((min * 100 / 100) + ((20 * min) / 100));
                                                            setFieldValue("monthly", [min.toString().replace(/(\.\d{2})\d*$/, "$1"), values?.monthly[1].toString().replace(/(\.\d{2})\d*$/, "$1")]);
                                                        } else {
                                                            let min = Number(e.target.value?.replaceAll(/[$,]/g, ""));
                                                            // let max = (min + ((20 * min) / 100));
                                                            setFieldValue("monthly", [min, values?.monthly[1]]);
                                                        }
                                                    } else if (!e.target.value) {
                                                        setFieldValue("monthly", [0, 0]);
                                                    }
                                                }
                                            }}
                                            inputMode="text"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className={`form-group ${values?.monthly[1] > 0 ? "has-value" : ""}`}>
                                        <CurrencyInput
                                            maxLength={9}
                                            name="min"
                                            className="form-control"
                                            placeholder="enter maximum value"
                                            decimalsLimit={2}
                                            allowDecimals={tabState === "monthly"}
                                            allowNegativeValue={false}
                                            value={!tabState === "monthly" || values?.monthly[1] === 0 ? "" : values?.monthly[1]}
                                            prefix="$"
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value?.replaceAll(/[$,]/g, ""))) {
                                                    if (tabState === "monthly") {
                                                        let max = e.target.value?.replaceAll(/[$,]/g, "");
                                                        // let min = ((80 * max) / 100);
                                                        setFieldValue("monthly", [values?.monthly[0].toString().replace(/(\.\d{2})\d*$/, "$1"), max.toString().replace(/(\.\d{2})\d*$/, "$1")]);
                                                    } else {
                                                        let max = Number(e.target.value?.replaceAll(/[$,]/g, ""));
                                                        // let min = ((80 * max) / 100).toFixed(0);
                                                        setFieldValue("monthly", [values?.monthly[0], max]);
                                                    }
                                                }
                                            }}
                                            inputMode="text"
                                            autoComplete="off"
                                        />
                                    </div>
                                </>
                            )}
                            {/* monthly */}
                            {/* yearly */}
                            {tabState === "yearly" && (
                                <>
                                    <div className={`form-group ${values?.yearly[0] > 0 ? "has-value" : ""}`}>
                                        <CurrencyInput
                                            maxLength={9}
                                            name="min"
                                            className="form-control"
                                            placeholder="enter minimum value"
                                            decimalsLimit={2}
                                            allowDecimals={tabState === "yearly"}
                                            allowNegativeValue={false}
                                            value={!tabState === "yearly" || values?.yearly[0] === 0 ? "" : values?.yearly[0]}
                                            prefix="$"
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value?.replaceAll(/[$,]/g, ""))) {
                                                    if (e.target.value && e.target.value?.replaceAll(/[$,]/g, "")?.length < 9) {
                                                        if (tabState === "yearly") {
                                                            let min = e.target.value?.replaceAll(/[$,]/g, "");
                                                            // let max = ((min * 100 / 100) + ((20 * min) / 100));
                                                            setFieldValue("yearly", [min.toString().replace(/(\.\d{2})\d*$/, "$1"), values?.yearly[1].toString().replace(/(\.\d{2})\d*$/, "$1")]);
                                                        } else {
                                                            let min = Number(e.target.value?.replaceAll(/[$,]/g, ""));
                                                            // let max = (min + ((20 * min) / 100));
                                                            setFieldValue("yearly", [min, values?.yearly[1]]);
                                                        }
                                                    } else if (!e.target.value) {
                                                        setFieldValue("yearly", [0, 0]);
                                                    }
                                                }
                                            }}
                                            inputMode="text"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className={`form-group ${values?.yearly[1] > 0 ? "has-value" : ""}`}>
                                        <CurrencyInput
                                            maxLength={9}
                                            name="min"
                                            className="form-control"
                                            placeholder="enter maximum value"
                                            decimalsLimit={2}
                                            allowDecimals={tabState === "yearly"}
                                            allowNegativeValue={false}
                                            value={!tabState === "yearly" || values?.yearly[1] === 0 ? "" : values?.yearly[1]}
                                            prefix="$"
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value?.replaceAll(/[$,]/g, ""))) {
                                                    if (tabState === "yearly") {
                                                        let max = e.target.value?.replaceAll(/[$,]/g, "");
                                                        // let min = ((80 * max) / 100);
                                                        setFieldValue("yearly", [values?.yearly[0].toString().replace(/(\.\d{2})\d*$/, "$1"), max.toString().replace(/(\.\d{2})\d*$/, "$1")]);
                                                    } else {
                                                        let max = Number(e.target.value?.replaceAll(/[$,]/g, ""));
                                                        // let min = ((80 * max) / 100).toFixed(0);
                                                        setFieldValue("yearly", [values?.yearly[0], max]);
                                                    }
                                                }
                                            }}
                                            inputMode="text"
                                            autoComplete="off"
                                        />
                                    </div>
                                </>
                            )}
                            {/* yearly */}
                            <div className='mt-3'>
                                <ErrorMessage name="hourly" component="div" className="invalid-feedback d-block mb-3" />
                                <ErrorMessage name="monthly" component="div" className="invalid-feedback d-block mb-3" />
                                <ErrorMessage name="yearly" component="div" className="invalid-feedback d-block mb-3" />
                            </div>
                            <div className='d-flex justify-content-end gap-10'>
                                <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                <button type='submit' className='btn btn-primary'>{props.currentItem !== null ? "Edit" : "Add"}</button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout >
    );
}

export default AddEditTotalCompensationModal;