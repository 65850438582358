import React, { useCallback, useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetReportListMutation, useUpdateReportedItemMutation } from '../../../rtk/services/reportApi';
import Loader from '../../../components/loader';
import { intToBool, useModuleAccess } from '../../../helper';
import { constants } from '../../../constants';
import moment from 'moment';

function ReportedDetails(props) {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Reported_Users, constants.privilegeType.Read);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Reported_Users, constants.privilegeType.Edit);
    const [item, setItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetReportListMutation();
    const [updateItem, { isSuccess: updateSuccess, isLoading: updateLoading, reset }] = useUpdateReportedItemMutation();

    const getItemList = useCallback(() => {
        getList({
            reported_id: id,
        });
    }, [id, getList]);
    const handleActions = async (item, type) => {
        await updateItem({
            reported_id: item?.id,
            report_type: type
        });
    }
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (updateSuccess) {
            reset();
            getItemList();
        }
    }, [updateSuccess])
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data?.[0]);
        }
    }, [resData, resSuccess]);
    const goBack = () => {
        if (location.search?.includes("reportDetail")) {
            navigate(`/reported${location?.search}`);
        } else {
            navigate(-1);
        }
    }
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {(resLoading || updateLoading) && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">report details</h5>

                            <div className="action_btns">
                                {hasReadPrivilege && (
                                    <button type="button" className="btn btn-primary" onClick={(e) => { goBack() }}>
                                        <i className="bi-arrow-left" /> Back
                                    </button>
                                )}
                                {hasEditPrivilege && (
                                    <>
                                        {!intToBool(item?.is_resolved) && (<button type="button" className="btn btn-primary" onClick={() => { handleActions(item, constants.reportEnum.resolved) }}> <i className="bi bi-person-check-fill" /> mark as resolved</button>)}
                                        {item?.reported_by == 1 ? intToBool(item?.reported_info?.js_reported_user_is_active) ? (<button type="button" className="btn btn-primary" onClick={() => { handleActions(item, constants.reportEnum.block) }}> <i className="fas fa-lock" /> block reported user</button>) : (<button type="button" className="btn btn-primary" onClick={() => { handleActions(item, constants.reportEnum.unblock) }}> <i className="fas fa-unlock" /> un block reported user</button>) : ""}
                                        {item?.reported_by == 0 ? intToBool(item?.reported_info?.hm_reported_user_is_active) ? (<button type="button" className="btn btn-primary" onClick={() => { handleActions(item, constants.reportEnum.block) }}> <i className="fas fa-lock" /> block reported user</button>) : (<button type="button" className="btn btn-primary" onClick={() => { handleActions(item, constants.reportEnum.unblock) }}> <i className="fas fa-unlock" /> un block reported user</button>) : ""}
                                    </>
                                )}
                            </div>

                        </div>
                        <div className="card-body details_style">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="text-group">
                                        <label>id</label>
                                        <p className="mb-0">{id}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-group">
                                        <label>status</label>
                                        <p className="mb-0">{intToBool(item?.is_resolved) ? "resolved" : "open"}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-group">
                                        <label>reported user</label>
                                        <p className="mb-0">
                                            <Link to={`/user/detail/${item?.reported_by == 1 ? item?.reported_info?.js_reported_user_uuid : item?.reported_info?.hm_reported_user_uuid}${location?.search}`} className='link-color'>{item?.reported_by == 1 ? item?.reported_info?.js_reported_user_name : item?.reported_info?.hm_reported_user_name || "-"}</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-group">
                                        <label>reporter</label>
                                        <p className="mb-0">
                                            <Link to={`/user/detail/${item?.reported_by == 1 ? item?.reported_info?.hm_reported_user_uuid : item?.reported_info?.js_reported_user_uuid}${location?.search}`} className='link-color'>{item?.reported_by == 1 ? item?.reported_info?.js_reporter_username : item?.reported_info?.hm_reporter_username || "-"}</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="text-group">
                                        <label>reported on</label>
                                        <p className="mb-0">{moment.utc(item?.created_at).local().format("MM/DD/YYYY hh:ss a") || "-"}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-group no-detail-style mb-0">
                                        <label>reason</label>
                                        <p className="mb-0">{item?.message || "-"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            )}
        </>

    );
}

export default ReportedDetails;