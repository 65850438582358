import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { decryptKeys, getSearchParamsFromSpecificPoint, getStatusType, intToBool, useModuleAccess, userTypeFunction } from '../../../helper';
import { constants } from '../../../constants';
import moment from 'moment';
import { useJobSoftSkillMutation } from '../../../rtk/services/userApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';

function SoftSkillsDetail(props) {
    const { id } = useParams();
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Soft_skills_answer, constants.privilegeType.Read);
    const [user, setUser] = useState("");
    const [itemList, setItemList] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useJobSoftSkillMutation();
    useEffect(() => {
        if (user?.user_type_Id == 1) {
            getList({ job_id: id });
        } else if (user?.user_type_Id == 0) {
            getList({ user_uuid: id });
        }
    }, [getList, id, user])
    useEffect(() => {
        if (location.search?.includes("currentUser")) {
            const paramsObject = getSearchParamsFromSpecificPoint(location.search, "currentUser=");
            setUser(paramsObject);
        }
    }, [location.search])
    useEffect(() => {
        if (resSuccess) {
            if (user?.user_type_Id == 1) {
                const mergedData = Object.values(resData?.data?.soft_skill.reduce((result, elm) => {
                    const { card_number, ...rest } = elm;

                    if (!result[card_number]) {
                        // If card_number doesn't exist, create a new entry
                        result[card_number] = { ...rest, cardTwo: {} };
                    }

                    // Add facet_name to the facet_names object
                    result[card_number].cardTwo = elm;

                    return result;
                }, {}));
                setItemList(mergedData);
            } else if (user?.user_type_Id == 0) {
                const mergedData = Object.values(resData?.data?.soft_skill?.[0]?.data?.reduce((result, elm) => {
                    const { card_number, ...rest } = elm;

                    if (!result[card_number]) {
                        // If card_number doesn't exist, create a new entry
                        result[card_number] = { ...rest, cardTwo: {} };
                    }

                    // Add facet_name to the facet_names object
                    result[card_number].cardTwo = elm;

                    return result;
                }, {}));
                setItemList(mergedData);
            }

        }
    }, [resData, resSuccess]);
    const goBack = () => {
        if (location.search?.includes("prevFilter")) {
            const prevFilter = location?.search?.split("prevFilter=")[1];
            let url = `/soft-skills-listing/list/${prevFilter}`;
            navigate(url);
        } else {
            navigate(-1);
        }
    }
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resFetching && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <button type="button" className="btn btn-primary ms-0" onClick={() => goBack()}>
                                <i className="bi bi-arrow-left" /> Back
                            </button>
                        </div>
                        <div className="card-body">
                            <div role="alert" className="fade mb-0 alert alert-dark show">
                                <div className="alert-heading h4">{user?.first_name || "-"} {user?.last_name || "-"} profile Details </div>
                                <p className="mb-0">
                                    <span className="badge rounded-pill bg-dark me-2">
                                        <i className="bi-star text-warning me-2" />
                                        {userTypeFunction(user?.user_type_Id) ? user?.hm_reliability_rating : user?.js_reliability_rating}
                                    </span>
                                    <span className={`badge rounded-pill me-2 ${intToBool(parseInt(user?.is_active)) ? "bg-success" : "bg-danger"}`} >{getStatusType(parseInt(user?.is_active))}</span>
                                    <span className="badge rounded-pill bg-dark">
                                        {parseInt(user?.user_type_Id) === constants.userTypeId.js ? 'Job Seeker' : parseInt(user?.user_type_Id) === constants.userTypeId.hm ? 'Hiring Manager' : parseInt(user?.user_type_Id) === constants.userTypeId.guest ? 'Invitee' : 'Both'}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`${parseInt(user?.userType) === constants.userTypeId.hm && "card "}`}>
                        {parseInt(user?.userType) === constants.userTypeId.hm && (
                            <div className="card-header">
                                <h5 className="card-title">
                                    job {id} - {user?.job_title}
                                    <i className="bi bi-clipboard-fill isCopy text-white ms-2 cursor-pointer" />
                                </h5>
                                <div>
                                    {intToBool(user?.job_is_active) && moment.utc(user?.job_trial_expired_date).local().diff(moment(), "seconds") > 0 && (<span className='badge rounded-pill ms-2'>active</span>)}
                                    {(intToBool(user?.job_is_active) && moment.utc(user?.job_trial_expired_date).local().diff(moment(), "seconds") < 0) || intToBool(user?.job_is_expired) && (<span className='badge bg-danger rounded-pill ms-2'>expired</span>)}

                                    {intToBool(user?.payment_status) && (<span className='badge bg-info rounded-pill  ms-2'>paid full</span>)}
                                    {(intToBool(user?.feedback_payment_status) && !intToBool(user?.payment_status)) && (<span className='badge bg-info rounded-pill  ms-2'>paid feedback</span>)}
                                    {(!intToBool(user?.feedback_payment_status) && !intToBool(user?.payment_status) && moment.utc(user?.job_trial_expired_date).local().isSameOrAfter()) && (<span className='badge bg-danger rounded-pill ms-2'>unpaid</span>)}
                                    {(!intToBool(user?.feedback_payment_status) && !intToBool(user?.payment_status) && !moment.utc(user?.job_trial_expired_date).local().isSameOrAfter()) && (<span className='badge bg-danger rounded-pill ms-2'>expired</span>)}
                                </div>
                            </div>
                        )}
                        <div className={`${parseInt(user?.userType) === constants.userTypeId.hm && "card-body "}`}>
                            <div className={`${parseInt(user?.userType) === constants.userTypeId.hm && "card-body px-0 pb-0"}`}>
                                <div className="card mb-0">
                                    <div className="card-header">
                                        <h5 className="card-title">detailed soft skills test</h5>
                                    </div>
                                    <div className="card-body pb-0">
                                        <div className="table-responsive">
                                            <div className="inner" />
                                            <table className="table table-striped no_wrap">
                                                <thead>
                                                    <tr>
                                                        <th>card no.</th>
                                                        <th>most like me</th>
                                                        <th>domain name</th>
                                                        <th>facet name</th>
                                                        <th>score</th>
                                                        <th>empty state</th>
                                                        <th>domain name</th>
                                                        <th>facet name</th>
                                                        <th>score</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {itemList?.length > 0 ? itemList?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td data-label="card no.">{item.cardTwo.card_number}</td>
                                                            <td data-label="most like me">{item.text}</td>
                                                            <td data-label="domain name">{item.domain_name}</td>
                                                            <td data-label="facet name">{item.facet_name}</td>
                                                            <td data-label="score">{item.score}</td>
                                                            <td data-label="empty state">{item.cardTwo.text}</td>
                                                            <td data-label="domain name">{item.cardTwo.domain_name}</td>
                                                            <td data-label="facet name">{item.cardTwo.facet_name}</td>
                                                            <td data-label="score">{item.cardTwo.score}</td>
                                                        </tr>
                                                    ))
                                                        :
                                                        <NoRecordsFound />
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </AdminLayout>
            )}
        </>
    );
}

export default SoftSkillsDetail;