import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import HmJobInfo from '../components/hm-job-info';
import JsJobTabs from '../components/js-job-tabs';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { useCandidateInterviewDetailMutation } from '../../../../rtk/services/userApi';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { intToBool } from '../../../../helper';
import Loader from '../../../../components/loader';

function JsJobInterviewDetails(props) {
    const { currentHmMatch } = useSelector(({ common }) => common);

    const [interview, setInterview] = useState(null);
    const [rescheduleInterview, setRescheduleInterview] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useCandidateInterviewDetailMutation();
    useEffect(() => {
        getList({
            candidate_uuid: currentHmMatch?.js_uuid,
            job_uuid: currentHmMatch?.job_uuid,
        });
    }, [getList, currentHmMatch]);
    useEffect(() => {
        if (resSuccess) {
            setInterview(resData?.interview?.filter((elm) => elm?.is_accepted == 1)?.[0]);
            console.log(resData?.interview?.some((elm) => elm?.is_accepted == 1))
            if (resData?.interview?.some((elm) => elm?.is_accepted == 1)) {

                setRescheduleInterview(resData?.rescheduled_interview);
            }
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <HmJobInfo>
                <JsJobTabs />
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="card-title d-flex align-items-center">
                            candidate {currentHmMatch?.js_user_unique_name || "-"} - {currentHmMatch?.js_first_name || "-"} {currentHmMatch?.js_last_name}
                            <span className="badge rounded-pill bg-dark ms-2"><i className="bi-star text-warning me-2" />{currentHmMatch?.js_reliability_rating}</span>
                        </h5>
                    </div>
                    <div className="card-body pb-0">
                        {/*  */}
                        {interview && (
                            <Alert variant="dark" className='payment-card mb-3'>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <div className='row align-items-center mb-3'>
                                            <div className="col-lg-3">current interview status</div>
                                            <div className="col-lg-auto">
                                                {interview?.user_info?.is_cancel ? (<span className="badge rounded-pill bg-danger ms-2">cancelled by {interview?.user_info?.cancelled_by === 1 ? "hiring manager" : interview?.user_info?.cancelled_by === 0 ? "job seeker" : ""} </span>) :
                                                    intToBool(interview?.is_accepted) ?
                                                        (<span className="badge rounded-pill bg-success ms-2">{!moment.utc(interview?.interview_date).local().add(30, "minutes").isSameOrAfter(moment(), "seconds") ? "completed" : "pending"}</span>)
                                                        : <span className="badge rounded-pill bg-success ms-2">pending</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className='row align-items-center mb-3'>
                                            <div className="col-lg-3">date</div>
                                            <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment.utc(interview?.interview_date).local().format("DD/MM/YYYY")}</span></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className='row align-items-center mb-3'>
                                            <div className="col-lg-3">day</div>
                                            <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment(interview?.interview_date).format("dddd")}</span></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className='row align-items-center'>
                                            <div className="col-lg-3">time</div>
                                            <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment.utc(interview?.interview_date).local().format("HH:mm")}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </Alert>
                        )}
                        {/*  */}
                        {(interview && rescheduleInterview?.length > 0) && (
                            <>
                                <hr />
                                <div className="card-header pt-0 pb-3 mb-3">
                                    <h5 className="card-title">previous scheduled details</h5>
                                </div>
                                {/*  */}
                                {rescheduleInterview?.map((elm, i) => (
                                    <Alert variant="dark" className='payment-card mb-3' key={i}>
                                        <div className='row'>
                                            <div className="col-lg-12">
                                                <div className='row align-items-center mb-3'>
                                                    <div className="col-lg-3">interview status</div>
                                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">rescheduled by {elm?.rescheduled_by === 1 ? "hiring manager" : elm?.rescheduled_by === 0 ? "job seeker" : ""}</span></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className='row align-items-center mb-3'>
                                                    <div className="col-lg-3">date</div>
                                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment.utc(elm?.rescheduled_at).local().format("DD/MM/YYYY")}</span></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className='row align-items-center mb-3'>
                                                    <div className="col-lg-3">day</div>
                                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment(elm?.rescheduled_at).format("dddd")}</span></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className='row align-items-center'>
                                                    <div className="col-lg-3">time</div>
                                                    <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{moment.utc(elm?.rescheduled_at).local().format("HH:mm")}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Alert>
                                ))}
                            </>
                        )}
                        {!interview && (<div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div>)}
                        {/*  */}
                    </div>
                </div>
            </HmJobInfo>
        </AdminLayout>
    );
}

export default JsJobInterviewDetails;