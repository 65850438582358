import React from "react";
import { Field, ErrorMessage, useField } from "formik";

const Input = ({ type, name, placeholder, disabled, autoFocus, tabIndex, readOnly, customClass, ...props }) => {
    const [field, meta] = useField(name);

    const hasValue = field.value !== "";
    const hasError = meta.touched && meta.error;
    return (
        <>
            {/* <div className={`input-group ${disabled ? "disabled" : ""} ${hasValue ? "has-value" : ""}`}>
                
            </div> */}
            <div className={`form-group ${disabled ? "disabled" : ""} ${hasValue ? "has-value" : ""}`}>
                <Field
                    type={type ? type : "text"}
                    as={type === "textarea" ? type : ""}
                    className={`form-control ${customClass || ""} ${hasError ? "validation-error-border" : ""}`}
                    {...field}
                    {...props}
                    placeholder={placeholder}
                    disabled={disabled ? disabled : false}
                    inputMode="text"
                    autoComplete="off"
                    readOnly={readOnly ? readOnly : false}
                // autoFocus={autoFocus ? autoFocus : false}
                // tabIndex={tabIndex !== undefined ? tabIndex : 0}
                />
            </div>
            <ErrorMessage name={name} component="div" className="invalid-feedback d-block mb-3" />
        </>
    );
};

export default Input;
