import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getUserListData: builder.mutation({
            query: (data) => {
                const url = `page_limit=${data.page_limit}&page_offset=${data.page_offset}&search_keyword=${data.search_keyword}&user_type=${data.user_type}&user_status=${data.user_status}&user_uuid=`
                return {
                    url: `/admin/user/?${url}`
                }
            },
        }),
        getUserDetails: builder.mutation({
            query: (data) => {
                const url = `page_limit=1&page_offset=1&search_keyword=&user_type=&user_status=&user_uuid=${data.user_uuid}`
                return {
                    url: `/admin/user/?${url}`
                }
            },
        }),
        updateUserStatus: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/`,
                    method: "put",
                    body
                }
            },
        }),
        deleteUser: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/?user_uuid=${body?.user_uuid}`,
                    method: "delete",
                }
            },
        }),
        userJobPosted: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/job/hm?page_limit=${body?.page_limit}&page_offset=${body?.page_offset}&user_uuid=${body?.user_uuid}&search_type=${body?.search_type}&payment_status=${body?.payment_status}`,
                    method: "get",
                }
            },
        }),
        userJobDetail: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/job/?job_uuid=${body?.job_uuid || ""}&page_limit=${body?.page_limit || 1}&page_offset=${body?.page_offset || 0}&search_keyword=${body?.search_keyword || ""}${(body?.is_active === 1 || body?.is_active === 0) ? `&is_active=${body?.is_active}` : ""}${body?.is_expired ? `&is_expired=${body?.is_expired || ""}` : ""}`,
                    method: "get",
                }
            },
        }),
        userFeedbackList: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/feedback?page_limit=${body?.page_limit || 10}&page_offset=${body?.page_offset || 1}&user_uuid=${body?.user_uuid}&feedback_stage=${body?.feedback_stage || 0}&job_uuid=${body?.job_uuid || ""}&history_id=${body?.history_id || ""}`,
                    method: "get",
                }
            },
        }),
        userMatchesList: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/job/match-candidate?page_limit=${body?.page_limit || 10}&page_offset=${body?.page_offset || 1}${body?.job_uuid ? `&job_uuid=${body?.job_uuid}` : ""}${body?.js_uuid ? `&js_uuid=${body?.js_uuid}` : ""}&hm_action=${body?.hm_action || 0}&js_action=${body?.js_action || 0}`,
                    method: "get",
                }
            },
        }),
        hmMatchesList: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/job/match-candidate-v1?job_uuid=${body?.job_uuid}${body?.hm_action ? `&hm_action=${body?.hm_action}` : ""}${body?.js_action ? `&js_action=${body?.js_action}` : ""}${body?.interview_status ? `&interview_status=${body?.interview_status}` : ""}&page_offset=${body?.page_offset || 1}&page_limit=${body?.page_limit || 10}`,
                    method: "get",
                }
            },
        }),
        userSwitchAccount: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/switch`,
                    method: "put",
                    body
                }
            },
        }),
        userPaymentHistory: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/invoice?page_limit=${body?.page_limit || 10}&page_offset=${body?.page_offset || 1}&search_keyword=${body?.search_keyword || ""}${body?.user_uuid ? `&user_uuid=${body?.user_uuid || ""}` : ""}${body?.job_id ? `&job_id=${body?.job_id || ""}` : ""}`,
                    method: "get",
                }
            },
        }),
        jobSoftSkill: builder.mutation({
            query: (body) => {
                return {
                    // user_uuid

                    url: `/admin/soft-skill/score?${body?.user_uuid ? `&user_uuid=${body?.user_uuid || ""}` : ""}${body?.job_id ? `&job_id=${body?.job_id || ""}` : ""}`,
                    method: "get",
                }
            },
        }),
        candidateJobDetail: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/job/candidate-detail?job_history_id=${body?.job_history_id}`,
                    method: "get",
                }
            },
        }),
        candidateRefineDetail: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/job/js-refine-search?js_uuid=${body?.js_uuid || ""}&job_history_id=${body?.job_history_id}`,
                    method: "get",
                }
            },
        }),
        candidateInterviewDetail: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/job/interview?candidate_uuid=${body?.candidate_uuid || ""}&job_uuid=${body?.job_uuid}`,
                    method: "get",
                }
            },
        }),
        candidateFeedbacks: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/feedback/hm?user_uuid=${body?.user_uuid || ""}&job_uuid=${body?.job_uuid}`,
                    method: "get",
                }
            },
        }),
        candidateFeedback: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/feedback?user_uuid=${body?.user_uuid || ""}&job_uuid=${body?.job_uuid}&feedback_stage=2&page_limit=20&page_offset=0`,
                    method: "get",
                }
            },
        }),

        userRefinePosted: builder.mutation({
            query: (body) => {
                return {
                    url: `/admin/user/job/js-refine-search?page_limit=${body?.page_limit}&page_offset=${body?.page_offset}&js_uuid=${body?.js_uuid}`,
                    method: "get",
                }
            },
        }),
        matchedSoftSkills: builder.mutation({
            query: (body) => {
                let url = `user_id=${body?.user_id}&job_id=${body?.job_id}&js_history_id=${body?.js_history_id}`;
                return {
                    url: `admin/soft-skill/comparison/js-hm?${url}`,
                    method: "get",
                }
            },
        }),
        unpaidReasonsList: builder.mutation({
            query: (body) => {
                let url = `job_id=${body?.job_id}`;
                return {
                    url: `admin/user/job/unpaid-reason?${url}`,
                    method: "get",
                }
            },
        }),
        getHardSkillScore: builder.mutation({
            query: (body) => {
                let url = `job_id=${body?.job_id}&js_user_id=${body?.js_user_id}&refine_id=${body?.refine_id}&history_id=${body?.history_id}`;
                return {
                    url: `admin/user/job/score?${url}`,
                    method: "get",
                }
            },
        }),
        getJSPostInterviewFeedback: builder.mutation({
            query: (body) => {
                let url = `history_id=${body?.history_id}&job_id=${body?.job_id}&user_type=${body?.user_type}`;
                return {
                    url: `admin/user/feedback/hm?${url}`,
                    method: "get",
                }
            },
        }),

    }),
});

export const {
    useGetUserListDataMutation,
    useGetUserDetailsMutation,
    useUpdateUserStatusMutation,
    useDeleteUserMutation,
    useUserJobPostedMutation,
    useUserFeedbackListMutation,
    useUserJobDetailMutation,
    useUserMatchesListMutation,
    useHmMatchesListMutation,
    useUserSwitchAccountMutation,
    useUserPaymentHistoryMutation,
    useJobSoftSkillMutation,
    useCandidateJobDetailMutation,
    useCandidateRefineDetailMutation,
    useCandidateInterviewDetailMutation,
    useCandidateFeedbacksMutation,
    useCandidateFeedbackMutation,
    useUserRefinePostedMutation,
    useMatchedSoftSkillsMutation,
    useUnpaidReasonsListMutation,
    useGetHardSkillScoreMutation,
    useGetJSPostInterviewFeedbackMutation
} = userApi;
