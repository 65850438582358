import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { Formik } from "formik";
import { useAddDepartmentSingleMutation, useEditDepartmentSingleMutation } from '../../rtk/services/lookupsApi';
import { toast } from 'react-toastify';
import ModalLayout from '../layouts/modalLayout';
import Loader from '../loader';
import Input from '../helper/Input';

const initialValues = {
    department_title: "",
};
const validationSchema = Yup.object().shape({
    department_title: Yup.string().required("department is required. please enter your department."),
});

function AddEditSingleDepartmentModal(props) {
    const [formData, setFormData] = useState(initialValues);
    const [addDepartment, { data: addData, isSuccess: addSuccess, isLoading: addFetching, error: addError }] = useAddDepartmentSingleMutation();
    const [editDepartment, { data: editData, isSuccess: editSuccess, isLoading: editFetching, error: editError }] = useEditDepartmentSingleMutation();
    useEffect(() => {
        if (props.currentItem) {
            let obj = {
                department_title: props.currentItem?.title,
            }
            setFormData(obj);
        }
    }, [props.currentItem]);
    const handleSubmit = (values) => {
        let payload = {};
        if (props.currentItem) {
            payload.id = props.currentItem?.id;
            payload.title = values?.department_title;
            editDepartment(payload);
        } else {
            payload.title = values?.department_title;
            addDepartment(payload);
        }
    }
    useEffect(() => {
        if (addSuccess || editSuccess) {
            toast.success(addData?.message || editData?.message);
            props.action();
            props.updateList();
        }
    }, [addSuccess, editSuccess, addError, editError, addData?.message, editData?.message, props])
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title}>
            {(addFetching || editFetching) && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Input type="text" name="department_title" placeholder="Department Name" />
                            <div className='d-flex justify-content-end gap-10'>
                                <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                <button type='submit' className='btn btn-primary'>{props.currentItem !== null ? "Edit" : "Add"}</button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout >
    );
}

export default AddEditSingleDepartmentModal;