import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import Input from '../../../components/helper/Input';
import { useAddPostInterviewQuestionMutation, useUpdatePostInterviewQuestionMutation } from '../../../rtk/services/lookupsApi';
import Loader from '../../../components/loader';
import { decryptKeys, intToBool, useModuleAccess } from '../../../helper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { constants } from '../../../constants';

const initialValues = {
    userType: "",
    question: "",
    options: [
        {
            option: '',
            subOptions: []
        },
        {
            option: '',
            subOptions: []
        }
    ],
    is_delete_enabled: true
};

const validationSchema = Yup.object().shape({
    userType: Yup.string().required("user type is required. please select your user type."),
    question: Yup.string().required("question text is required. please enter your question text."),
    options: Yup.array().of(
        Yup.object().shape({
            option: Yup.string().nullable().required("option is required. please enter your option type."),
            subOptions: Yup.array().of(
                Yup.object().shape({
                    subOption: Yup.string().nullable().required("Sub Option is required. Please enter your sub option type.")
                })
            )
        })
    ),
    is_delete_enabled: Yup.bool(),
});

function AddEditInterviewQuestions(props) {
    const { id } = useParams();
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Read);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Edit);
    const navigate = useNavigate();
    const location = useLocation();
    const [formValues, setFormValues] = useState(initialValues);
    const [addItem, addItemResponse] = useAddPostInterviewQuestionMutation();
    const [updateItem, updateItemResponse] = useUpdatePostInterviewQuestionMutation();
    useEffect(() => {
        if (id) {
            let decryptedItem = decryptKeys(location?.search?.split("=")?.[1]);
            let obj = {
                userType: intToBool(decryptedItem?.user_type) ? "hm" : "js",
                question: decryptedItem?.question || "",
                is_delete_enabled: intToBool(decryptedItem?.is_delete_enabled),
                options: decryptedItem?.options?.map((elm, i) => {
                    return {
                        option: elm?.option,
                        subOptions: elm?.sub_options?.map(subOption => { return { subOption: subOption?.sub_option_text } }) || []
                    };
                })
            }
            setFormValues(obj);
        }
    }, [id, location?.search])
    const handleAddField = (values, setValues) => {
        const newField = { option: '', subOptions: [] };
        const updatedFields = [...values.options, newField];
        setValues({ ...values, options: updatedFields });
    };

    const handleRemoveField = (index, values, setValues) => {
        const updatedFields = [...values.options];
        updatedFields.splice(index, 1);
        setValues({ ...values, options: updatedFields });
    };
    const handleAddSubOption = (optionIndex, values, setValues) => {
        const newSubOption = { subOption: '' };
        const updatedOptions = [...values.options];
        updatedOptions[optionIndex].subOptions.push(newSubOption);
        setValues({ ...values, options: updatedOptions });
    };

    const handleRemoveSubOption = (optionIndex, subOptionIndex, values, setValues) => {
        const updatedOptions = [...values.options];
        updatedOptions[optionIndex].subOptions.splice(subOptionIndex, 1);
        setValues({ ...values, options: updatedOptions });
    };
    const handleSubmit = (values) => {
        let options = [];
        let decryptedItem = "";
        if (id) {
            decryptedItem = decryptKeys(location?.search?.split("=")?.[1]);
        }
        values?.options?.map((elm, i) => {
            let optionObj = {
                is_deleted: 0,
                option: elm?.option,
                question_id: decryptedItem?.options?.[i]?.question_id,
                option_id: decryptedItem?.options?.[i]?.option_id,
                is_sub_option: elm?.subOptions?.length > 0 ? 1 : 0,
                sub_options: (elm?.subOptions || []).map((subElm, i) => ({ is_deleted: 0, option_id: subElm?.option_id, sub_option_id: subElm?.sub_option_id, question_id: subElm?.question_id, sub_option_text: subElm?.subOption }))
            }
            if (!optionObj?.option_id) {
                delete optionObj?.option_id
                delete optionObj?.question_id
            }
            optionObj?.sub_options?.map((subElm, i) => {
                if (!subElm?.sub_option_id) {
                    delete subElm?.option_id;
                    delete subElm?.question_id;
                    delete subElm?.sub_option_id;
                }
            })
            options.push(optionObj);
        })
        let obj = {
            is_deleted: 0,
            question_type: "POST_INTERVIEW",
            user_type: values?.userType === "hm" ? 1 : 0,
            question: values?.question,
            is_delete_enabled: values?.is_delete_enabled ? 1 : 0,
            options: options
        }
        if (id) {
            obj["question_id"] = parseInt(id);
            updateItem(obj);
        } else {
            addItem(obj);
        }
    }
    useEffect(() => {
        if (addItemResponse.isSuccess || updateItemResponse.isSuccess) {
            navigate("/lookups/interview-questions");
        }
    }, [addItemResponse.isSuccess, navigate, updateItemResponse.isSuccess]);
    return (
        <>
            {(hasReadPrivilege && hasEditPrivilege) && (
                <AdminLayout>
                    {(addItemResponse.isLoading || updateItemResponse.isLoading) && (<Loader />)}
                    <Formik
                        enableReinitialize={true}
                        initialValues={formValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({
                            values,
                            handleSubmit,
                            setValues
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title">{id ? "edit" : "add"} post interview questions</h5>
                                            <div className="action_btns">
                                                <button type="submit" className="btn btn-primary"> <i className="bi bi-save" /> Submit</button>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className='text-group'>
                                                        <label htmlFor="userType" className='ms-2'>User type</label>
                                                        <div className="row">
                                                            <div className="col-lg-auto">
                                                                <div className="custom_checkbox">
                                                                    <label htmlFor="hiringManager">
                                                                        <Field type="radio" id="hiringManager" className="form-check-input me-2" name="userType" value={"hm"} />
                                                                        <span>hiring manager</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-auto">
                                                                <div className="custom_checkbox">
                                                                    <label htmlFor="jobSeeker">
                                                                        <Field type="radio" id="jobSeeker" className="form-check-input me-2" name="userType" value={"js"} />
                                                                        <span>job seeker</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="userType" component="div" className='invalid-feedback d-block' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <label htmlFor="question" className='ms-2 text-white'>question</label>
                                                    <Input type="textarea" name="question" placeholder="text" style={{ height: "100px", resize: "none" }} />
                                                </div>
                                            </div>
                                            <hr className='my-0' />
                                            <div className="card-header">
                                                <h5 className="card-title">options</h5>
                                            </div>
                                            <div className="card-body pb-0 px-0">
                                                {values?.options?.map((field, index) => (
                                                    <div className="row" key={index}>
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <label htmlFor={`options[${index}].option`} className='ms-2 text-white'>option {index + 1}</label>
                                                                    <Input type="text" name={`options[${index}].option`} placeholder="text" disabled={(field?.option == "yes" || field?.option == "no") && (id == 3 || id == 4)} />
                                                                </div>
                                                                <div className="col-2 input-with-btn">
                                                                    {values?.options.length >= 3 && (
                                                                        <button type='button' className='btn btn-sm btn-primary btn-icon position-relative' style={{ right: 0 }} onClick={() => handleRemoveField(index, values, setValues)}>
                                                                            <i className="bi bi-x-lg text-white" />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    {field?.subOptions?.map((subOption, subOptionIndex) => (
                                                                        <div className="row" key={subOptionIndex}>
                                                                            <div className="col-10 input-with-btn">
                                                                                <label htmlFor={`options[${index}].subOptions[${subOptionIndex}].subOption`} className='ms-2 text-white'>Sub option {subOptionIndex + 1}</label>
                                                                                <Input type="text" name={`options[${index}].subOptions[${subOptionIndex}].subOption`} placeholder="text" />

                                                                            </div>
                                                                            <div className="col-2 input-with-btn">
                                                                                {field?.subOptions?.length >= 3 && (
                                                                                    <button type='button' className='btn btn-sm btn-primary btn-icon position-relative' style={{ right: 0 }} onClick={() => handleRemoveSubOption(index, subOptionIndex, values, setValues)}>
                                                                                        <i className="bi bi-x-lg text-white" />
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {field?.subOptions?.length > 0 && (
                                                                    <div className="col-10 text-end">
                                                                        <button type='button' className='btn btn-primary' onClick={() => handleAddSubOption(index, values, setValues)}>
                                                                            <i className="bi bi-plus text-white" /> add sub option
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="row">
                                                    <div className="col-lg-5 col-10 text-end">
                                                        <button type="button" className="btn btn-primary" disabled={(values?.options?.some((elm) => { return (elm?.option == "yes" || elm?.option == "no") }) && (id == 3 || id == 4))} onClick={() => handleAddField(values, setValues)}> <i className="bi bi-plus" /> add option</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </AdminLayout>
            )}
        </>

    );
}

export default AddEditInterviewQuestions;