import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

function JsJobSearchTabs(props) {
    const location = useLocation();
    const { search_id, user_uuid } = useParams();
    return (
        <div className="custom_tabs">
            <div className="nav-tabs nav full-width">
                <Link to={"/user/job-search/details/" + search_id + "/" + user_uuid} className={`nav-link ${location?.pathname?.includes("/user/job-search/details") ? "active" : ""}`}>job history</Link>
                <Link to={"/user/job-search/refine-details/" + search_id + "/" + user_uuid} className={`nav-link ${location?.pathname?.includes("/user/job-search/refine-details") ? "active" : ""}`}>refine search</Link>
                <Link to={"/user/job-search/matches/" + search_id + "/" + user_uuid} className={`nav-link ${location?.pathname?.includes("/user/job-search/matches") ? "active" : ""}`}>matches</Link>
            </div>
        </div >
    );
}

export default JsJobSearchTabs;