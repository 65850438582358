import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { constants } from '../../../constants';
import { useGetExportRequestsMutation } from '../../../rtk/services/exportApi';
import Loader from '../../../components/loader';
import CommonPagination from '../../../components/shared/CommonPagination';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useModuleAccess } from '../../../helper';

function ExportList(props) {
    const [itemList, setItemList] = useState([]);
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Export_requests, constants.privilegeType.Read);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useGetExportRequestsMutation();
    useEffect(() => {
        getList({
            page_offset: pageOffset + 1,
            page_limit: pageLimit,
        });
    }, [getList, pageLimit, pageOffset]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_count);
        }
    }, [resData, resSuccess]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {(resFetching) && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">export list ({totalCount})</h5>
                        </div>
                        <div className="card-body pb-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>Sr. no.</th>
                                            <th>user name</th>
                                            <th>request type</th>
                                            <th>created at</th>
                                            <th>status</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ? itemList?.map((item, i) => (
                                                <tr key={i}>
                                                    <td data-label="Sr. no.">{(pageOffset) * pageLimit + i + 1}</td>
                                                    <td data-label="user name">{item?.user_details?.first_name || "-"} {item?.user_details?.last_name || "-"}</td>
                                                    <td data-label="request type">{constants.exportKeysValue[item?.type]?.replaceAll("_", " ") || "-"}</td>
                                                    <td data-label="created at">{moment.utc(item?.created_at).local().format("DD MMM YYYY hh:mm:ss")}</td>
                                                    <td data-label="status">{item?.url ? "completed" : "pending"}</td>
                                                    <td data-label="action">
                                                        <div className="action_wrap">
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Download</Tooltip>}>
                                                                <Link to={item?.url || "#"} target="_blank" title="download" className={`btn btn-icon ${!item?.url && "disabled"}`}>
                                                                    <i className="bi-download" />
                                                                </Link>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                                :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                        </div>
                    </div>
                </AdminLayout>
            )}
        </>
    );
}

export default ExportList;