import React from "react";
import { Field, ErrorMessage, useField } from "formik";

const Select = ({ type, name, placeholder, disabled, autoFocus, tabIndex, readOnly, data, onChangeFunc, ...props }) => {
    const [field, meta] = useField(name);

    const hasValue = field?.value !== "";
    const hasError = meta.touched && meta.error;
    return (
        <>
            <div className={`form-group ${disabled ? "disabled" : ""} ${hasValue ? "has-value" : ""}`}>
                <Field
                    type="select"
                    as={"select"}
                    className={`form-control ${hasError ? "validation-error-border" : ""}`}
                    {...field}
                    {...props}
                    disabled={disabled ? disabled : false}
                    inputMode="text"
                    autoComplete="off"
                    readOnly={readOnly ? readOnly : false}
                    onChange={(e) => {
                        onChangeFunc && onChangeFunc(e);
                        // Call the default onChange handler from Formik
                        field.onChange(e);
                    }}
                >
                    <option value="">Select</option>
                    {data?.map((elm, i) => (
                        <option key={i} value={elm?.value}>{elm?.title}</option>
                    ))}
                </Field>
            </div>
            <ErrorMessage name={name} component="div" className="invalid-feedback d-block mb-3" />
        </>
    );
};

export default Select;
