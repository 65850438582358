import React from 'react';
import { useNavigate } from 'react-router-dom';

function BackButton(props) {
    const navigate = useNavigate();
    return (
        <>
            <button type="button" className="btn btn-primary" onClick={(e) => { navigate(-1) }}>
                <i className="bi-arrow-left" />
                Back
            </button>
        </>
    );
}

export default BackButton;