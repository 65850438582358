import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "../utils/baseQuery";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (body) => {
                return {
                    url: "/admin/login",
                    method: "post",
                    headers: {},
                    body,
                };
            },
        }),
        forgotPassword: builder.mutation({
            query: (body) => {
                return {
                    url: "/user/actions/forgot-password",
                    method: "post",
                    headers: {},
                    body,
                };
            },
        }),
        resetPassword: builder.mutation({
            query: (body) => {
                return {
                    url: "/user/actions/reset-password",
                    method: "put",
                    headers: {},
                    body,
                };
            },
        }),
        changePassword: builder.mutation({
            query: (body) => {
                return {
                    url: "/user/change-password",
                    method: "post",
                    headers: {},
                    body,
                };
            },
        }),
        logoutUser: builder.mutation({
            query: (body) => {
                return {
                    url: "/user/logout",
                    method: "post",
                    headers: {},
                    body,
                };
            },
        }),
        moduleAccess: builder.mutation({
            query: (body) => {
                return {
                    url: "/role-management/module-access",
                    method: "get",
                    headers: {},
                    body,
                };
            },
        }),
    })

});
export const {
    useLoginUserMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useChangePasswordMutation,
    useLogoutUserMutation,
    useModuleAccessMutation
} = authApi;