import React, { useEffect } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useState } from 'react';
import { constants } from '../../../constants';
import { useDebounce } from 'use-debounce';
import CommonPagination from '../../../components/shared/CommonPagination';
import { Link, useLocation } from 'react-router-dom';
import { useUserJobDetailMutation } from '../../../rtk/services/userApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { intToBool, useModuleAccess } from '../../../helper';
import { useDispatch } from 'react-redux';
import { setCurrentUser, setJobsTrue } from '../../../rtk/feature/commonSlice';
import ExportButton from '../../../components/helper/exportButton';

function JobsList(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [itemList, setItemList] = useState([]);
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Jobs_management, constants.privilegeType.Read);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [searchKey, setSearchKey] = useState("");
    const [searchValue] = useDebounce(searchKey, 1000);
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [statusType, setStatusType] = useState("");
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useUserJobDetailMutation();
    useEffect(() => {
        getList({
            page_offset: pageOffset + 1,
            page_limit: pageLimit,
            search_keyword: searchValue,
            is_active: statusType === "active" ? 1 : statusType === "blocked" ? 0 : "",
            is_expired: statusType === "expired" ? 1 : "",
        });
    }, [getList, pageLimit, pageOffset, searchValue, statusType]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resSuccess, resData]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    useEffect(() => {
        if (location.search?.includes("pageOffset")) {
            let params = new URLSearchParams(location.search);
            const search = params.get("search");
            const statusType = params.get("statusType");
            const pageOffset = params.get("pageOffset");
            setSearchKey(search);
            setStatusType(statusType);
            setPageOffset(parseInt(pageOffset));
        }
    }, [location.search])
    const handleJobs = () => {
        dispatch(setJobsTrue())
    }
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resFetching && (<Loader />)}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">All jobs ({totalCount})</h5>
                            <div className="action_btns">
                                <ExportButton type={constants.exportKeys.jobs} />
                            </div>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input type="text" className='form-control' placeholder='Search by job title, company name, industry, department, email' value={searchKey} onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="form-group select-option">
                                        <select className="form-control form-select" value={statusType} onChange={(e) => { setStatusType(e.target.value); setPageOffset(0) }}>
                                            {constants.statusTypeTwo?.map((item, i) => (
                                                <option key={i} value={item?.value}>{item?.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>sr. no.</th>
                                            <th>job id</th>
                                            <th>job title</th>
                                            <th>hm email's</th>
                                            <th>company name</th>
                                            <th>industry</th>
                                            <th>department</th>
                                            <th>status</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itemList.length > 0 ? itemList?.map((item, i) => (
                                            <tr key={i}>
                                                <td data-label="sr. no.">{(pageOffset) * pageLimit + i + 1}</td>
                                                <td data-label="job id">{item?.job_id || "-"}</td>
                                                <td data-label="job title">{item?.custom_role || item?.role || "-"}</td>
                                                <td data-label="hm email's">{item?.work_email || "-"}</td>
                                                <td data-label="company name">{item?.company_name || "-"}</td>
                                                <td data-label="industry">{item?.industry || "-"}</td>
                                                <td data-label="department">{item?.department || "-"}</td>
                                                <td data-label="status">
                                                    {(intToBool(item?.is_active) && intToBool(item?.is_expired)) && "expired"}
                                                    {(intToBool(item?.is_active) && !intToBool(item?.is_expired)) && "active"}
                                                    {(!intToBool(item?.is_active) && !intToBool(item?.is_expired)) && "blocked"}
                                                </td>
                                                <td data-label="action">
                                                    <div className="action_wrap">
                                                        {hasReadPrivilege && (
                                                            <Link to={"/user/job/details/" + item?.job_uuid + `?userUuid=${item?.uuid}&search=${searchKey}&statusType=${statusType}&pageOffset=${pageOffset}&type=jobDetail`} className="btn btn-icon" onClick={() => { dispatch(setCurrentUser(item)), handleJobs() }}>
                                                                <i className="bi-eye" />
                                                            </Link>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <NoRecordsFound />}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                        </div>
                    </div>
                </AdminLayout>
            )}
        </>

    );
}

export default JobsList;