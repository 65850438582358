import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

function JsJobTabs(props) {
    const location = useLocation();
    const { job_uuid } = useParams();
    const { currentHmJob, currentHmMatch } = useSelector(({ common }) => common);
    return (
        <div className="custom_tabs">
            <div className="nav-tabs nav full-width">
                <Link to={"/user/job/match/detail/" + job_uuid} className={`nav-link ${location?.pathname?.includes("/user/job/match/detail/") ? "active" : ""}`}>candidate's details</Link>
                <Link to={"/user/job/match/soft-skills/" + job_uuid + `?job_id=${currentHmJob?.job_id}&user_id=${currentHmMatch?.js_user_id}`} className={`nav-link ${location?.pathname?.includes("/user/job/match/soft-skills") ? "active" : ""}`}>soft skills match</Link>
                <Link to={"/user/job/match/interview-details/" + job_uuid} className={`nav-link ${location?.pathname?.includes("/user/job/match/interview-details") ? "active" : ""}`}>interview details</Link>
                <Link to={"/user/job/match/feedback/" + job_uuid} className={`nav-link ${location?.pathname?.includes("/user/job/match/feedback") ? "active" : ""}`}>feedback</Link>
            </div>
        </div >
    );
}

export default JsJobTabs;