import React from 'react';
import PublicRoute from '../PublicRoute';

function AuthLayout({ children }) {
    return (
        <PublicRoute>
            <section className="account_page">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <div className="col-lg-12">
                            <div className="right_side">
                                <div className="row g-0">
                                    <div className="col-lg-12">
                                        {children}
                                    </div>
                                    {/* <div className="col-lg-6 d-none d-lg-block">
                                    <div className="form_info hasImg w-100">
                                        <img src="/assets/images/home-logo.svg" className="image-fit-contain" alt="logo" />
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </PublicRoute>
    );
}

export default AuthLayout;