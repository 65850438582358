import React, { useEffect } from 'react';
import { useGenerateExportRequestMutation } from '../../rtk/services/exportApi';
import { customSwalFire } from '../../helper';

function ExportButton({ type }) {
    const [actionRequest, response] = useGenerateExportRequestMutation();
    const handleGenerateRequest = async () => {
        let title = "Export?"
        let textMessage = "export will take time for further updates pls check export menu."
        customSwalFire(title, textMessage, "/assets/images/icon/export.svg").then(async (result) => {
            if (result.isConfirmed) {
                await actionRequest({
                    type: type
                });
            }
        })

    };
    useEffect(() => {
        if (response.isSuccess) {
            response.reset();
        }
    }, [response]);
    return (
        <>
            <button type="button" className="btn btn-primary" onClick={() => handleGenerateRequest()}> <i className="bi bi-file-earmark-arrow-down-fill" /> Export</button>
        </>
    );
}

export default ExportButton;