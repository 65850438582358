import React, { useCallback, useEffect, useState } from 'react';
import PricingLayout from '../../../components/layouts/pricingLayout';
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';
import ContextAwareToggle from '../../../components/helper/custom-accordion';
import { useGetPriceListMutation } from '../../../rtk/services/priceApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { priceFormat, useModuleAccess } from '../../../helper';
import AddEditPricingModal from '../../../components/modals/addEditPricingModal';
import { constants } from '../../../constants';
import CommonPagination from '../../../components/shared/CommonPagination';

function MatchFeedback(props) {
    const [itemList, setItemList] = useState([]);
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Pricing, constants.privilegeType.Read);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Pricing, constants.privilegeType.Edit);
    const [addEditModal, setAddEditModal] = useState(false);
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const pageLimit = constants.common.limit;
    const [totalCount, setTotalCount] = useState(0);
    const [currentType, setCurrentType] = useState(constants.priceCategories.GLOBAL);
    const [currentItem, setCurrentItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading }] = useGetPriceListMutation();
    const getItemList = useCallback(() => {
        getList({
            page_offset: pageOffset + 1,
            page_limit: pageLimit,
            list_type: constants.priceTabs.ONLY_FEEDBACK,
            price_category: currentType
        });
    }, [getList, currentType, pageLimit, pageOffset]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.data?.[0]?.total_count);
        }
    }, [resData, resSuccess]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <>
            {hasReadPrivilege && (
                <PricingLayout>
                    {resLoading && (<Loader />)}
                    <div className="card mb-0">
                        <div className="card-header">
                            <h5 className="card-title">match feedback</h5>
                        </div>
                        <div className="card-body">
                            <Accordion defaultActiveKey={0}>
                                {/* soft skill */}
                                <Card key={0}>
                                    <ContextAwareToggle setCurrentType={setCurrentType} type={constants.priceCategories.GLOBAL} eventKey={0}>feedback payment</ContextAwareToggle>
                                    <Accordion.Collapse eventKey={0}>
                                        <Card.Body>
                                            <div className="table-responsive mb-0">
                                                <div className="inner" />
                                                <table className="table table-striped no_wrap">
                                                    <thead>
                                                        <tr>
                                                            <th>feedback paid</th>
                                                            <th>fee ($)</th>
                                                            <th>action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {itemList.length > 0 ?
                                                            itemList?.map((elm, i) => (
                                                                <tr key={i}>
                                                                    <td data-label="feedback paid">{elm?.price_type_value || "-"}</td>
                                                                    <td data-label="fee ($)">{(elm?.price_type_value_amount && (priceFormat(elm?.price_type_value_amount))) || "-"}</td>
                                                                    {hasEditPrivilege && (
                                                                        <td data-label="action">
                                                                            <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(elm); }}>
                                                                                <i className="bi-pencil" />
                                                                            </button>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))
                                                            :
                                                            <NoRecordsFound />
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                    <div className="card-footer">
                                        {currentType === constants.priceCategories.GLOBAL && (
                                            <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                                        )}
                                    </div>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
                    {addEditModal && (<AddEditPricingModal state={addEditModal}
                        action={() => { setAddEditModal(false); setCurrentItem(null) }} title={`hm match feedback - payment - ${currentType?.replaceAll(/_|ideal|candidate/g, " ")}`} type={currentType} currentItem={currentItem} updateList={() => getItemList()} />)}
                </PricingLayout>
            )}
        </>
    );
}

export default MatchFeedback;