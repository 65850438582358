import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { useAddEditDepartmentMutation, useGetDepartmentListSingleMutation } from '../../rtk/services/lookupsApi';
import { toast } from 'react-toastify';
import ModalLayout from '../layouts/modalLayout';
import Loader from '../loader';
import Input from '../helper/Input';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';

const initialValues = {
    checkBoxes: [],
};
const validationSchema = Yup.object().shape({
    checkBoxes: Yup.array().min(1, "please select departments.").required("please select departments."),
});

function AddEditDepartmentModal(props) {
    const { uuid } = useParams();
    const [searchText, setSearchText] = useState("");
    const [searchValue] = useDebounce(searchText, 1000);
    const [itemList, setItemList] = useState([]);
    const [formData, setFormData] = useState(initialValues);
    const [getList, { data: resItems, isLoading: resItemsLoading, isSuccess: resItemsSuccess }] = useGetDepartmentListSingleMutation();
    const [addEditDepartment, { data: resData, isSuccess: resSuccess, isLoading: resFetching, error: resError }] = useAddEditDepartmentMutation()
    useEffect(() => {
        if (props.currentItem?.length > 0) {
            let obj = {
                checkBoxes: props.currentItem?.map((elm) => { return elm?.department_id })
            }
            setFormData(obj);
        }
    }, [props.currentItem]);
    const handleSubmit = (values) => {
        addEditDepartment({
            industry_uuid: uuid,
            department_id: values?.checkBoxes?.map((elm) => { return JSON.parse(elm) })
        });
    }
    useEffect(() => {
        if (resSuccess) {
            toast.success(resData?.message);
            props.action();
            props.updateList();
        }
    }, [resSuccess, resError, resData?.message, props]);
    useEffect(() => {
        if (resItemsSuccess) {
            setItemList(resItems?.data);
        }
    }, [resItemsSuccess, resItems])
    useEffect(() => {
        getList({ page_limit: 1000 });
    }, [getList, searchValue])
    return (
        <ModalLayout state={props.state} action={props.action} title={props.title}>
            {(resFetching || resItemsLoading) && (<Loader />)}
            <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    handleSubmit,
                    errors,
                    setFieldValue
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            {/* <div className="form-group">
                                <input type="text" className='form-control' placeholder='search...' onChange={(e) => { setSearchText(e.target.value); }} />
                            </div> */}
                            <div className="min-height-form">
                                {itemList?.length > 0 ? itemList?.map((elm, i) => (
                                    <div className="custom_checkbox" key={i}>
                                        <label htmlFor={`active-${elm?.id}`}>
                                            <input
                                                type="checkbox"
                                                id={`active-${elm?.id}`}
                                                className="form-check-input me-2"
                                                name={`checkBoxes`}
                                                value={elm?.id}
                                                checked={values.checkBoxes?.includes(elm?.id)}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    const id = elm?.id;
                                                    if (isChecked) {
                                                        // If checked, add ID to the array
                                                        setFieldValue("checkBoxes", [...values.checkBoxes, id]);
                                                    } else {
                                                        // If unchecked, remove ID from the array
                                                        setFieldValue(
                                                            "checkBoxes",
                                                            values.checkBoxes.filter((item) => item !== id)
                                                        );
                                                    }
                                                }}
                                            />
                                            <span>{elm?.title || ""}</span>
                                        </label>
                                    </div>
                                )) : "No Departments Found"}
                            </div>
                            <ErrorMessage name="checkBoxes" component="div" className="invalid-feedback d-block mb-3" />
                            <div className='d-flex justify-content-end gap-10 mt-4'>
                                <button type='button' className='button outer-login' onClick={() => props.action()}>Cancel</button>
                                <Button type='submit' className='btn btn-primary'>{props.currentItem?.length > 0 ? "Edit" : "Add"}</Button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </ModalLayout >
    );
}

export default AddEditDepartmentModal;