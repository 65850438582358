import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import JsJobInfo from '../components/js-job-info';
import HmJobSearchTabs from '../components/hm-job-search-tabs';
import { Alert } from 'react-bootstrap';
import { handleCopyItem } from '../../../../helper';
import { useGetJSPostInterviewFeedbackMutation } from '../../../../rtk/services/userApi';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/loader';

function HmJsJobInterviewFeedback(props) {
    const [item, setItem] = useState(null);
    const [itemList, setItemList] = useState(null);
    const [total, setTotal] = useState(null);
    const { currentJsMatch } = useSelector(({ common }) => common);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useGetJSPostInterviewFeedbackMutation();
    useEffect(() => {
        getList({
            job_id: currentJsMatch?.job_id,
            history_id: parseInt(currentJsMatch?.user_details?.job_history_id),
            user_type: 1
        });
    }, [getList, currentJsMatch]);
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data);
            setItemList(resData?.data?.data);
            setTotal(resData?.total_count);
        }
    }, [resData, resSuccess]);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <JsJobInfo>
                <HmJobSearchTabs />
                <div className="card-body px-0 pb-0">
                    {total !== 0 && (<>
                        <Alert variant="dark" className='payment-card mb-3'>
                            <Alert.Heading className='d-flex justify-content-between mb-3 h6'>
                                <p className='mb-0'>job {currentJsMatch?.job_details?.job_id || "-"} - {currentJsMatch?.job_details?.job_title || "-"}</p>
                            </Alert.Heading>
                            <div className='row'>
                                <div className="col-lg-12">
                                    <div className='row align-items-center mb-3'>
                                        <div className="col-lg-3">hiring manager's name</div>
                                        <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{item?.hm_first_name || "-"} {item?.hm_last_name}</span></div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className='row align-items-center'>
                                        <div className="col-lg-3">hiring manager's work email</div>
                                        <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{item?.hm_email || "-"}</span> <i className="bi bi-clipboard-fill isCopy text-white ms-2 cursor-pointer" onClick={() => handleCopyItem(item?.hm_email)} /></div>
                                    </div>
                                </div>
                            </div>
                        </Alert>
                        <div className="card mb-0">
                            <div className="card-header">
                                <h5 className="card-title">Feedback by hiring manager</h5>
                            </div>
                            <div className="card-body">
                                {/*  */}
                                {itemList?.map((elm, i) => (
                                    <div className={`row ${i !== itemList.length - 1 ? 'mb-4' : ''}`} key={i}>
                                        <div className="col-auto">
                                            <div className="custom_checkbox">
                                                <label htmlFor="titleOne" className='pe-0'>
                                                    <input type="checkbox" id="titleOne" className="form-check-input me-2" defaultChecked={true} />
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <h6 className='text-white mb-2'>{elm?.question || "-"}</h6>
                                            <p className='text-white mb-0'>{elm?.response_option || "-"}</p>
                                            {elm?.sub_options?.length > 0 && (
                                                <div className="row mt-3">
                                                    {elm?.sub_options?.map((sub, j) => (
                                                        <div key={j} className="col-auto">
                                                            <div className="custom_checkbox">
                                                                <label className=' px-3 mb-0'>
                                                                    <input type="checkbox" className="form-check-input me-2" defaultChecked={true} />
                                                                    <span>{sub?.sub_option_text || "-"}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {/*  */}
                            </div>
                        </div>
                    </>)}
                    {total == 0 && (<div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div>)}
                </div>
            </JsJobInfo>
        </AdminLayout>
    );
}

export default HmJsJobInterviewFeedback;