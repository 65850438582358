import React, { useEffect, useState } from 'react';
import AuthLayout from '../../components/layouts/authLayout';
import Input from '../../components/helper/Input';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from "yup";
import { constants } from '../../constants';
import { useDispatch } from 'react-redux';
import { useLoginUserMutation, useModuleAccessMutation } from '../../rtk/services/authApi';
import Loader from '../../components/loader';
import { toast } from 'react-toastify';
import { setAccess, setUser } from '../../rtk/feature/authSlice';
import { setter } from '../../rtk/utils/StorageUtil';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email(constants?.validations?.invalidEmail)
        .required(constants?.validations?.requiredEmail),
    password: Yup.string().required(constants?.validations?.requiredPassword),
});

const initialValues = {
    email: "",
    password: "",
}

function Login(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [togglePassword, setTogglePassword] = useState(false);
    const [loginUser, res] = useLoginUserMutation();
    const [moduleAccess, { isSuccess: moduleSuccess, isLoading: moduleLoading, data: moduleData, reset }] = useModuleAccessMutation();
    const handleSubmit = async (values) => {
        const obj = {
            email: values.email,
            password: values.password,
        };
        await loginUser(obj);
    }

    useEffect(() => {
        if (res.isSuccess) {
            // toast.success("Logged in successfully");
            dispatch(setUser(res?.data));
            setter('user', JSON.stringify(res?.data));
            moduleAccess();
            res.reset();
        }
    }, [res.isSuccess, res?.data, navigate, dispatch, res]);

    useEffect(() => {
        if (moduleSuccess) {
            const enhancedData = moduleData.data.reduce((acc, item) => {
                const { module_id, privilege_id } = item;

                // Find existing module object or create a new one if it doesn't exist
                let module = acc.find(m => m.module_id === module_id);
                if (!module) {
                    module = {
                        module_id,
                        module_name: constants?.moduleIds[module_id],
                        privileges: []
                    };
                    acc.push(module); // Add the new module object to the accumulator array
                }

                // Add the privilege to the module object
                module.privileges.push({
                    privilege_id: privilege_id,
                    privilege_name: constants?.privilegeIds[privilege_id]
                });

                return acc;
            }, []);

            dispatch(setAccess(enhancedData));
            navigate('/dashboard');
            reset();

        }

    }, [moduleSuccess]);

    return (
        <AuthLayout>
            {(res.isLoading || moduleLoading) && (<Loader />)}
            <div className="form_info">
                <h5 className="title mb-2 text-white">Sign In</h5>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit} className="formbox mt-4">
                                <Input type="email" name="email" placeholder="Email ID" />
                                <div className={`form-group type_password  ${values?.password?.trim() !== "" ? "has-value" : ""}`}>
                                    {/* Password input */}
                                    <Field className="form-control" type={togglePassword ? 'text' : 'password'} name="password" placeholder="Password" />
                                    {/* Toggle password visibility button */}
                                    <button type="button" className="togglePassword transform-center" onClick={() => { setTogglePassword(!togglePassword) }}>
                                        <i className={togglePassword ? 'bi-eye-slash' : 'bi-eye'} />
                                    </button>
                                </div>
                                <ErrorMessage name="password" component="div" className="invalid-feedback d-block mb-3" />
                                <div className="forgotPass mb-3">
                                    {/* Forgot password link */}
                                    <Link to={"/forgot-password"} className="link">Forgot Password ?</Link>
                                </div>
                                {/* Sign In button */}
                                <button type="submit" className="btn btn-primary w-100">
                                    Sign In
                                </button>
                            </form>
                        );
                    }}
                </Formik>
            </div>

        </AuthLayout>
    );
}

export default Login;