import React from 'react';
import { useSelector } from 'react-redux';
import Widgets from '../../pages/admin/dashboard/components/widgets';

function SharedWidgets({ title, show, moduleFor }) {
    const { dashboard } = useSelector(({ common }) => common)
    return (
        <div className="card has_col">
            <div className="card-header">
                <h5 className="card-title">{title}</h5>
            </div>
            <div className="card-body">
                {(moduleFor === "dashboard") && (
                    <div className="row">
                        {[
                            { title: "Total Users", value: dashboard?.total_users },
                            { title: "Job Seekers", value: dashboard?.total_js },
                            { title: "Hiring Manager", value: dashboard?.total_hm },
                            { title: "Total Job Posted", value: dashboard?.job_posted },
                            { title: "Total Interviews", value: dashboard?.total_interview },
                            { title: "Total Revenue", value: dashboard?.total_revenue },
                            { title: "Pre Interview Rejections", value: dashboard?.total_pre_interview_rejection },
                            { title: "Post Interview Rejections", value: dashboard?.total_post_interview_rejection },
                            { title: "Interview Qualified", value: dashboard?.total_qualified_interview },
                            { title: "Cancelled Interview", value: dashboard?.canceled_interview },
                        ].slice(0, show).map((condition, index) => (
                            <Widgets key={index} title={condition.title} value={condition.value} />
                        ))}
                    </div>
                )}
                {(moduleFor === "users") && (
                    <div className="row">
                        {[
                            { title: "Job Posted", value: dashboard?.job_posted },
                            { title: "Total rejections <small>(by hm)</small>", value: dashboard?.total_rejection_by_hm },
                            { title: "Total acceptance <small>(by hm)</small>", value: dashboard?.total_acceptence_by_hm },
                            { title: "Total rejections <small>(by js)</small>", value: dashboard?.total_rejection_by_js },
                            { title: "Total acceptance <small>(by js)</small>", value: dashboard?.total_acceptence_by_js },
                        ].slice(0, show).map((condition, index) => (
                            <Widgets key={index} title={condition.title} value={condition.value} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SharedWidgets;