import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import { useState } from 'react';
import { constants } from '../../../../constants';
import { useCallback } from 'react';
import BackButton from '../../../../components/helper/backButton';
import CommonPagination from '../../../../components/shared/CommonPagination';
import AddEditLanguageModal from '../../../../components/modals/AddEditLanguageModal';
import { useDebounce } from 'use-debounce';
import { useGetHiringQuestionSingleMutation } from '../../../../rtk/services/lookupsApi';
import { useEffect } from 'react';
import Loader from '../../../../components/loader';
import NoRecordsFound from '../../../../components/shared/NoRecordsFound';
import { useModuleAccess } from '../../../../helper';
import ExportButton from '../../../../components/helper/exportButton';

function HiringSoftwareLanguages(props) {
    const [addEditModal, setAddEditModal] = useState(false);
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Read);
    const { hasCreatePrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Create);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Edit);
    const [currentItem, setCurrentItem] = useState(null);
    const [itemList, setItemList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const [searchKey, setSearchKey] = useState("");
    const [searchValue] = useDebounce(searchKey, 1000);
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    const [getList, { data: resData, isLoading: resLoading, isSuccess: resSuccess }] = useGetHiringQuestionSingleMutation();
    const getItemList = useCallback(() => {
        getList({
            search_keyword: searchValue,
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
            hiring_type: "software"
        });
    }, [getList, pageLimit, pageOffset, searchValue]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resSuccess, resData]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resLoading && (<Loader />)}
                    <div className="card has_col">
                        <div className="card-header">
                            <h5 className="card-title">software languages ({totalCount})</h5>
                            <div className="action_btns">
                                {hasCreatePrivilege && (
                                    <button type="button" className="btn btn-primary" onClick={() => { setAddEditModal(true); setCurrentItem(null); }}> <i className="bi bi-plus" /> Add</button>
                                )}
                                <ExportButton type={constants.exportKeys.software_languages} />
                                <BackButton />
                            </div>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-group">
                                        <input type="text" className='form-control' placeholder='Search by name' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>name</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ? itemList?.map((item, i) => (
                                                <tr key={i}>
                                                    <td data-label="name">{item?.title || "-"}</td>
                                                    {hasEditPrivilege && (
                                                        <td data-label="action">
                                                            <div className="action_wrap">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(item); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                                :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                        </div>
                    </div>
                    {addEditModal && (<AddEditLanguageModal state={addEditModal}
                        action={() => { setAddEditModal(false); setCurrentItem(null) }} title={`${currentItem !== null ? "Edit" : "Add"} software language`} currentItem={currentItem} updateList={() => getItemList()} />)}
                </AdminLayout>
            )}
        </>

    );
}

export default HiringSoftwareLanguages;