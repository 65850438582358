import React from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import { Link } from 'react-router-dom';
import { constants } from '../../../../constants';
import { useModuleAccess } from '../../../../helper';

function HiringQuestions(props) {
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Manage_Lookups, constants.privilegeType.Read);

    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">hiring questions</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive mb-0">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>hiring question</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {constants.hiringQuestions?.map((item, i) => (
                                            <tr key={i}>
                                                <td data-label="hiring question">{item?.title || "-"}</td>
                                                {hasReadPrivilege && (
                                                    <td data-label="action">
                                                        <Link to={item?.link} className="btn btn-icon">
                                                            <i className="bi-eye" />
                                                        </Link>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            )}
        </>

    );
}

export default HiringQuestions;