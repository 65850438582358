import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../../components/layouts/adminLayout';
import UserInfo from '../components/userInfo';
import HmJobInfo from '../components/hm-job-info';
import HmJobTabs from '../components/hm-job-tabs';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { useJobSoftSkillMutation } from '../../../../rtk/services/userApi';
import Loader from '../../../../components/loader';

function HmJobSoftSkills(props) {
    const { job_uuid } = useParams();
    const location = useLocation();
    const [item, setItem] = useState(null);
    const queryParams = new URLSearchParams(location?.search);
    const key1Value = queryParams.get('job_id');
    const { currentHmJob } = useSelector(({ common }) => common);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resFetching }] = useJobSoftSkillMutation();
    useEffect(() => {
        getList({
            job_id: key1Value
        });
    }, [getList, key1Value])
    useEffect(() => {
        if (resSuccess) {
            setItem(resData?.data);
        }
    }, [resData, resSuccess]);
    const userItem = item;
    const groupedData = item?.soft_skill?.reduce((result, elm) => {
        const domainName = elm.domain_name?.replaceAll("\r\n", "");
        const facetName = elm.facet_name?.replaceAll("\r\n", "");
        const existingDomain = result.find(item => item.domainName?.replaceAll("\r\n", "") === domainName);


        if (existingDomain) {
            const existingFacet = existingDomain.facetNames.find(facet => facet.facetName?.replaceAll("\r\n", "") === facetName);
            if (existingFacet) {
                existingFacet.score += elm.score; // Assuming score is a numeric value
            } else {
                existingDomain.facetNames.push({ facetName, score: elm.score });
            }
        } else {
            result.push({
                domainName,
                facetNames: [{ facetName, score: elm.score }]
            });
        }
        return result;
    }, []);
    return (
        <AdminLayout>
            {resFetching && (<Loader />)}
            <UserInfo />
            <HmJobInfo>
                <HmJobTabs />
                <div className="card-body px-0 pb-0 ">
                    <div className="card mb-0">
                        <div className="card-header">
                            <h5 className="card-title">soft skills test{(userItem && item?.soft_skill.length > 0) && (userItem?.user_details?.user_type_id !== 3) ? " - self" : ""}</h5>
                            {(userItem && item?.soft_skill.length > 0) && (
                                <div className="action_btns">
                                    <Link to={`/user/job/soft-skill/details/${job_uuid}?job_id=${currentHmJob?.job_id}`} className='btn btn-primary'>see details soft skills test</Link>
                                </div>
                            )}
                        </div>
                        <div className="card-body pb-0">
                            {/* if invitee */}
                            {userItem?.user_details?.user_type_id === 3 && (
                                <Alert variant="dark" className='payment-card'>
                                    <Alert.Heading className='d-flex justify-content-between mb-3 h6'>
                                        <p className='mb-0'>{userItem?.user_details?.user_type_id === 3 ? "invitee" : ""}</p>
                                    </Alert.Heading>
                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <div className='row align-items-center mb-3'>
                                                <div className="col-lg-3">Name</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{userItem?.user_details?.first_name || "-"} {userItem?.user_details?.last_name || "-"}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className='row align-items-center mb-3'>
                                                <div className="col-lg-3">Seniority Level</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{userItem?.job_details?.level || "-"}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className='row align-items-center mb-0'>
                                                <div className="col-lg-3">Email Address</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{userItem?.user_details?.work_email || "-"}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className='row align-items-center mb-0'>
                                                <div className="col-lg-3">Job title</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{userItem?.job_details?.title || "-"}</span></div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6">
                                            <div className='row align-items-center'>
                                                <div className="col-lg-3">Phone Number</div>
                                                <div className="col-lg-auto"><span className="badge rounded-pill bg-info ms-2">{userItem?.user_details?.mobile_number || "-"}</span></div>
                                            </div>
                                        </div> */}
                                    </div>
                                </Alert>
                            )}
                            {/* end invitee */}
                            {groupedData?.map((elm, i) => (
                                <div className="card mb-3" key={i}>
                                    <div className="card-header">
                                        <h5 className="card-title">Domain:&nbsp;{elm?.domainName || "-"}</h5>
                                    </div>
                                    <div className="card-body pb-0">
                                        <div className="table-responsive">
                                            <div className="inner" />
                                            <table className="table table-striped no_wrap">
                                                <thead>
                                                    <tr>
                                                        <th>Facet Name</th>
                                                        <th>Score</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {elm?.facetNames?.map((facet, i) => (
                                                        <tr key={i}>
                                                            <td data-label="Facet Name">{facet?.facetName || "-"}</td>
                                                            <td data-label="Score">{facet?.score || "-"}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {item?.soft_skill.length === 0 && (<div className='col-12'><div className='text-center p-3 bg-dark text-white rounded-3 mb-3'>No Records Found</div></div>)}
                            {/*  */}
                        </div>
                    </div>
                </div>
            </HmJobInfo>
        </AdminLayout>
    );
}

export default HmJobSoftSkills;