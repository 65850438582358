import { createSlice } from "@reduxjs/toolkit";
import { setter } from "../utils/StorageUtil";

const initialState = {
    user: null,
    access: []
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action) => {
            setter("user", JSON.stringify(action.payload));
            state.user = action.payload;
        },
        sign_Out: (state, action) => {
            localStorage.clear();
            state.user = null;
        },
        clearAuth: (state) => {
            state.user = null;
        },
        updateRefreshTokenAndToken: (state, action) => {
            let tokenInfo = action.payload;
            state.user = { ...state.user, ...tokenInfo }
        },
        setAccess: (state, action) => {
            state.access = JSON.stringify(action.payload);
        },
        clearAccess: (state) => {
            state.access = []
        }
    }
});

export const {
    setUser,
    sign_Out,
    clearAuth,
    updateRefreshTokenAndToken,
    setAccess,
    clearAccess
} = authSlice.actions;

export default authSlice.reducer;
