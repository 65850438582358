import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { constants } from '../../../../constants';

function HmJobTabs(props) {
    const location = useLocation();
    const { job_uuid } = useParams();
    const { currentHmJob } = useSelector(({ common }) => common);
    return (
        <div className="custom_tabs">
            <div className="nav-tabs nav full-width">
                <Link to={"/user/job/details/" + job_uuid} className={`nav-link ${location?.pathname?.includes("/user/job/details") ? "active" : ""}`}>job opening details</Link>
                <Link to={"/user/job/soft-skills/" + job_uuid + `?job_id=${currentHmJob?.job_id}`} className={`nav-link ${location?.pathname?.includes("/user/job/soft-skills") ? "active" : ""}`}>soft skills test</Link>
                <Link to={"/user/job/matches/" + job_uuid} className={`nav-link ${location?.pathname?.includes("/user/job/matches") ? "active" : ""}`}>matches</Link>
                {(currentHmJob?.job_details?.job_payment_status !== 1 && moment(currentHmJob?.job_details?.job_trial_expired_date).diff(moment(), "days") <= 0) && (
                    <Link to={`/user/job/unpaid/${job_uuid}?job_id=${currentHmJob?.job_id}`} className={`nav-link ${location?.pathname?.includes("/user/job/unpaid") ? "active" : ""}`}>unpaid job reasons</Link>
                )}
            </div>
        </div >
    );
}

export default HmJobTabs;