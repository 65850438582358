import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { userTypeFunction } from '../../../../helper';

function UserTabs(props) {
    const location = useLocation();
    const { currentUser } = useSelector(({ common }) => common);
    return (
        <div className="custom_tabs">
            <div className="nav-tabs nav full-width">
                <Link to={"/user/detail/" + currentUser?.uuid + `${location?.search}`} className={`nav-link ${location?.pathname?.includes("/user/detail") ? "active" : ""}`}>Basic Details</Link>
                {userTypeFunction(currentUser?.user_type_Id) && (
                    <>
                        <Link to={"/user/company/" + currentUser?.uuid + `${location?.search}`} className={`nav-link ${location?.pathname?.includes("user/company") ? "active" : ""}`}>Company Details</Link>
                        <Link to={"/user/job-posts/" + currentUser?.uuid + `${location?.search}`} className={`nav-link ${location?.pathname?.includes("user/job-posts") ? "active" : ""}`}>Job Posted</Link>
                        <Link to={"/user/payments/" + currentUser?.uuid + `${location?.search}`} className={`nav-link ${location?.pathname?.includes("user/payments") ? "active" : ""}`}>Payment History</Link>
                        <Link to={"/user/feedbacks/" + currentUser?.uuid + `${location?.search}`} className={`nav-link ${location?.pathname?.includes("user/feedbacks") ? "active" : ""}`}>Feedback</Link>
                    </>
                )}
                {!userTypeFunction(currentUser?.user_type_Id) && (
                    <>
                        <Link to={"/user/soft-skills/" + currentUser?.uuid + `${location?.search}`} className={`nav-link ${location?.pathname?.includes("user/soft-skills") ? "active" : ""}`}>Soft skills test</Link>
                        <Link to={"/user/job-searches/" + currentUser?.uuid + `${location?.search}`} className={`nav-link ${location?.pathname?.includes("user/job-searches") ? "active" : ""}`}>job searches</Link>
                    </>
                )}
            </div>
        </div >
    );
}

export default UserTabs;