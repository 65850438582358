import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useModuleAccess } from '../../../helper';
import { constants } from '../../../constants';

function PricingTabs(props) {
    const location = useLocation();
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Pricing, constants.privilegeType.Read);
    return (
        <>
            {hasReadPrivilege && (
                <div className="custom_tabs">
                    <div className="nav-tabs nav full-width">
                        <Link to={"/pricing/company-info"} className={`nav-link ${location?.pathname?.includes("/pricing/company-info") ? "active" : ""}`}>hm company info</Link>
                        <Link to={"/pricing/job-openings"} className={`nav-link ${location?.pathname?.includes("/pricing/job-openings") ? "active" : ""}`}>hm job opening details</Link>
                        <Link to={"/pricing/ideal-candidate"} className={`nav-link ${location?.pathname?.includes("/pricing/ideal-candidate") ? "active" : ""}`}>hm job ideal candidates details</Link>
                        <Link to={"/pricing/soft-skills"} className={`nav-link ${location?.pathname?.includes("/pricing/soft-skills") ? "active" : ""}`}>soft skill test</Link>
                        <Link to={"/pricing/match-feedback"} className={`nav-link ${location?.pathname?.includes("/pricing/match-feedback") ? "active" : ""}`}>match feedback</Link>
                    </div>
                </div>
            )}
        </>
    );
}

export default PricingTabs;