import React, { useEffect } from 'react';
import AdminLayout from '../../../components/layouts/adminLayout';
import { useState } from 'react';
import { constants } from '../../../constants';
// import { useDebounce } from 'use-debounce';
import CommonPagination from '../../../components/shared/CommonPagination';
import AddEditSoftSkillsModal from '../../../components/modals/addEditSoftSkillsModal';
import { useCallback } from 'react';
import { useGetSkillsQuestionMutation } from '../../../rtk/services/skillsApi';
import Loader from '../../../components/loader';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import { getStatusType, useModuleAccess } from '../../../helper';
import ExportButton from '../../../components/helper/exportButton';

function SoftSkillsList(props) {
    const [itemList, setItemList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const pageLimit = constants.common.limit;
    const { hasReadPrivilege } = useModuleAccess(constants.moduleType.Manage_Skill_Quiz, constants.privilegeType.Read);
    const { hasEditPrivilege } = useModuleAccess(constants.moduleType.Manage_Skill_Quiz, constants.privilegeType.Edit);
    const [pageOffset, setPageOffset] = useState(constants.common.page);
    // const [searchKey, setSearchKey] = useState("");
    // const [searchValue] = useDebounce(searchKey, 1000);
    const [addEditModal, setAddEditModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [getList, { data: resData, isSuccess: resSuccess, isLoading: resLoading, error: resError }] = useGetSkillsQuestionMutation();
    const getItemList = useCallback(() => {
        getList({
            page_limit: pageLimit,
            page_offset: pageOffset + 1,
        });
    }, [pageLimit, pageOffset, getList]);
    useEffect(() => {
        getItemList();
    }, [getItemList]);
    useEffect(() => {
        if (resSuccess) {
            setItemList(resData?.data);
            setTotalCount(resData?.total_records);
        }
    }, [resData, resError, resSuccess]);
    const handlePagination = (data) => {
        setPageOffset(data.selected);
        return data.selected;
    }
    return (
        <>
            {hasReadPrivilege && (
                <AdminLayout>
                    {resLoading && (<Loader />)}
                    <div className="card has_col">
                        <div className="card-header">
                            <h5 className="card-title">soft skills questions ({totalCount})</h5>
                            <div className="action_btns">
                                {/* <button type="button" className="btn btn-primary" onClick={() => { setAddEditModal(true); setCurrentItem(null); }}> <i className="bi bi-plus" /> Add</button> */}
                                <ExportButton type={constants.exportKeys.softSkills} />
                            </div>
                        </div>
                        {/* <div className="card-body pb-0">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                                <input type="text" className='form-control' placeholder='Search by question text, id' onChange={(e) => { setSearchKey(e.target.value); setPageOffset(0) }} />
                            </div>
                        </div>
                    </div>
                </div> */}
                        <div className="card-body pb-0">
                            <div className="table-responsive">
                                <div className="inner" />
                                <table className="table table-striped no_wrap">
                                    <thead>
                                        <tr>
                                            <th>card no.</th>
                                            <th>question text</th>
                                            <th>domain name</th>
                                            <th>facet name</th>
                                            <th>paired with</th>
                                            <th>paired domain name</th>
                                            <th>paired facet name</th>
                                            <th>status</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemList?.length > 0 ? itemList?.map((item, i) => (
                                                <tr key={i}>
                                                    <td data-label="card no.">{item?.card_number || "-"}</td>
                                                    <td data-label="question text">{item?.questions?.[0]?.text || "-"}</td>
                                                    <td data-label="domain name">{item?.questions?.[0]?.domain_name || "-"}</td>
                                                    <td data-label="facet name">{item?.questions?.[0]?.facet_name || "-"}</td>
                                                    <td data-label="paired with">{item?.questions?.[1]?.text || "-"}</td>
                                                    <td data-label="paired domain name">{item?.questions?.[1]?.domain_name || "-"}</td>
                                                    <td data-label="paired facet name">{item?.questions?.[1]?.facet_name || "-"}</td>
                                                    <td data-label="status">{getStatusType(item?.isEjecting) || "-"}</td>
                                                    {hasEditPrivilege && (
                                                        <td data-label="action">
                                                            <div className="action_wrap">
                                                                <button type="button" className="btn btn-icon" onClick={(e) => { setAddEditModal(true); setCurrentItem(item); }}>
                                                                    <i className="bi-pencil" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                                :
                                                <NoRecordsFound />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <CommonPagination hitAction={handlePagination} total={totalCount} pageLimit={pageLimit} currentPage={pageOffset} />
                        </div>
                    </div>
                    {addEditModal && (<AddEditSoftSkillsModal state={addEditModal}
                        action={() => { setAddEditModal(false); setCurrentItem(null) }} title={`${currentItem !== null ? "Edit" : "Add"} soft skill question`} currentItem={currentItem} updateList={() => getItemList()} />)}
                </AdminLayout>
            )}
        </>
    );
}

export default SoftSkillsList;