import React, { useState } from 'react';
import Navbar from '../shared/navbar';
import Topbar from '../shared/topbar';
import PrivateRoute from '../PrivateRoute';

function AdminLayout({ children }) {
    const [toggleMethod, setToggleMethod] = useState(false);
    return (
        <PrivateRoute>
            <section className="page_body">
                <div className={`left_side ${toggleMethod ? "active" : ""}`}>
                    <Navbar />
                </div>
                <div className={`right_side ${toggleMethod ? "active" : ""}`}>
                    <div className="page_topbar">
                        <Topbar action={() => setToggleMethod(!toggleMethod)} />
                    </div>
                    <main className="content_wrapper h-100-70 overflow-y-auto">
                        <div className="w-100">
                            {children}
                        </div>
                    </main>
                </div>
                <div id="sidebar-overlay" onClick={() => { setToggleMethod(!toggleMethod) }} className={`${toggleMethod ? "active" : ""}`} />
            </section>
        </PrivateRoute>
    );
}

export default AdminLayout;